import type { Client, RequestContext } from "../client";
import type { GitCommit, GitPersistenceSettings, GitRefResource, ProjectResource, ReleaseResource, ReleaseTemplateResource } from "../resources";
import type { DeploymentProcessResource, ModifyDeploymentProcessCommand } from "../resources/deploymentProcessResource";
type GetRawDeploymentProcessOclResponseV1 = {
    Ocl: string;
};
type ModifyRawDeploymentProcessOclResponseV1 = {
    Commit?: GitCommit;
};
export class DeploymentProcessRepository {
    readonly resourceLink = "DeploymentProcess";
    readonly collectionLink = "DeploymentProcesses";
    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly gitRef: GitRefResource | undefined, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    get(): Promise<DeploymentProcessResource> {
        if (this.project.IsVersionControlled && this.gitRef !== undefined) {
            return this.client.get(this.requestContext, this.gitRef.Links[this.resourceLink]);
        }
        return this.client.get(this.requestContext, this.project.Links[this.resourceLink]);
    }
    // If you're using this, you've probably made a wrong turn
    // This method grabs the deployment process for a project from the DB or off the default
    // branch regardless of what branch is currently selected.
    getForDefaultBranch(): Promise<DeploymentProcessResource> {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const gitSettings = this.project.PersistenceSettings as GitPersistenceSettings;
        if (this.project.IsVersionControlled && gitSettings) {
            return this.client.get(this.requestContext, this.project.Links[this.resourceLink], { gitRef: gitSettings.DefaultBranch });
        }
        return this.client.get(this.requestContext, this.project.Links[this.resourceLink]);
    }
    getForRelease(release: ReleaseResource): Promise<DeploymentProcessResource> {
        return this.client.get<DeploymentProcessResource>(this.requestContext, this.client.getLink(this.collectionLink), { id: release.ProjectDeploymentProcessSnapshotId });
    }
    getTemplate(deploymentProcess: DeploymentProcessResource, channelId: string, releaseId: string) {
        return this.client.get<ReleaseTemplateResource>(this.requestContext, deploymentProcess.Links["Template"], { channel: channelId, releaseId });
    }
    getTemplateForGitRef(gitRef: GitRefResource, channelId: string) {
        return this.client.get<ReleaseTemplateResource>(this.requestContext, gitRef.Links["ReleaseTemplate"], { channel: channelId });
    }
    getRawOcl() {
        return this.client.get<GetRawDeploymentProcessOclResponseV1>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/deploymentprocesses/raw", {
            spaceId: this.project.SpaceId,
            projectId: this.project.Id,
            gitRef: this.gitRef?.CanonicalName,
        });
    }
    modifyRawOcl(ocl: string, commitMessage?: string) {
        return this.client.post<ModifyRawDeploymentProcessOclResponseV1>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/{gitRef}/deploymentprocesses/raw", {
            spaceId: this.project.SpaceId,
            projectId: this.project.Id,
            gitRef: this.gitRef?.CanonicalName,
            ocl,
            changeDescription: commitMessage,
        }, {
            spaceId: this.project.SpaceId,
            projectId: this.project.Id,
            gitRef: this.gitRef?.CanonicalName,
        });
    }
    async modify(deploymentProcess: ModifyDeploymentProcessCommand): Promise<DeploymentProcessResource> {
        const updatedDeploymentProcess = await this.client.update(this.requestContext, deploymentProcess.Links.Self, deploymentProcess);
        this.client.dispatchEvent({ type: "DeploymentProcessModified" });
        return updatedDeploymentProcess;
    }
}
