export type RouteTemplate<T> = Array<string | RequiredRouteParameter<string & keyof T> | SpaceIdPlaceholderRouteParameter>;
export function routeTemplate<T extends RouteTemplateExpression[]>(strings: TemplateStringsArray, ...expressions: T): RouteTemplate<RouteTemplateParameters<T>> {
    return strings.flatMap((s, i) => {
        const expression: RouteTemplateExpression = expressions[i];
        if (expression) {
            return isRouteParameter(expression) || isSpaceIdPlaceholder(expression) ? [s, expression] : [s, ...expression];
        }
        return [s];
    });
}
export function routeParam<T extends string>(name: T, matchRegExp?: RegExp): RequiredRouteParameter<T> {
    return {
        type: "parameter",
        name,
        matchRegExp,
    };
}
export const spaceIdPlaceholder: SpaceIdPlaceholderRouteParameter = { type: "space Id Placeholder" };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouteParametersOf<T extends RouteTemplate<any>> = T extends RouteTemplate<infer RouteParameters> ? RouteParameters : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRouteParameter(value: RouteTemplateExpression): value is RequiredRouteParameter<any> {
    return "type" in value && value.type === "parameter";
}
export function isSpaceIdPlaceholder(value: RouteTemplateExpression): value is SpaceIdPlaceholderRouteParameter {
    return "type" in value && value.type === "space Id Placeholder";
}
export interface RequiredRouteParameter<T extends string> {
    type: "parameter";
    name: T;
    matchRegExp?: RegExp;
}
// System scoped routes have a spaceId in the route, which is used to store the state of the space switcher.
// However, the pages rendered by these routes don't have knowledge of a spaceId, nor should they. This makes the spaceId a special case.
// We use this placeholder as a way of representing the location in the route for the space Id to live, while also excluding it from the route parameters.
export type SpaceIdPlaceholderRouteParameter = {
    type: "space Id Placeholder";
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteTemplateExpression = RequiredRouteParameter<any> | RouteTemplate<any> | SpaceIdPlaceholderRouteParameter;
// Given an array of RouteTemplateExpressions from the `routeTemplate` template tag, we want to merge these down into a single object containing all the parameters.
type RouteTemplateParameters<T extends RouteTemplateExpression[]> = MergeObjects<{
    [P in keyof T]: T[P] extends RequiredRouteParameter<infer Name> ? {
        [N in Name]: string;
    } : T[P] extends RouteTemplate<infer RouteParameters> ? RouteParameters : T[P] extends SpaceIdPlaceholderRouteParameter ? {} : never;
}>;
type MergeObjects<T extends unknown[]> = T extends [
    infer Value
] ? Value : T extends [
    infer Value,
    ...infer Rest
] ? Value & MergeObjects<Rest> : {};
