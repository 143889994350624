import React, { useContext } from "react";
const AnalyticLinkLocationContext = React.createContext<string | null>(null);
interface AnalyticLinkLocationContextProps {
    location: string;
}
export function AnalyticLinkLocationProvider(props: React.PropsWithChildren<AnalyticLinkLocationContextProps>) {
    return <AnalyticLinkLocationContext.Provider value={props.location}>{props.children}</AnalyticLinkLocationContext.Provider>;
}
export function useAnalyticLinkLocation(): string | null {
    const location = useContext(AnalyticLinkLocationContext);
    return location;
}
