/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { typeSafeHasOwnProperty } from "../utils";
import type { BlueprintProcessResource } from "./blueprintResource";
import type { ICanBeVersionControlled } from "./canBeVersionControlledResource";
import type { ICommitCommand } from "./commitCommand";
import type { DeploymentStepResource } from "./deploymentStepResource";
import { Permission } from "./permission";
import type { ResourceWithId } from "./resource";
import type { RunbookProcessResource } from "./runbookProcessResource";
interface DeploymentProcessResourceLinks {
    Self: string;
    Project: string;
    Template: string;
}
export interface IProcessResource extends ResourceWithId<DeploymentProcessResourceLinks> {
    Id: string;
    SpaceId: string;
    ProjectId: string;
    Steps: DeploymentStepResource[];
    LastSnapshotId?: string;
    Version: number;
    NewGitRef?: string;
}
export type DeploymentProcessResource = IProcessResource & ICanBeVersionControlled;
export type ModifyDeploymentProcessCommand = DeploymentProcessResource & ICommitCommand;
export function isDeploymentProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<IProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as DeploymentProcessResource;
    return !isRunbookProcessResource(resource) && !isBlueprintProcessResource(resource) && converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}
export function isRunbookProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<RunbookProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as RunbookProcessResource;
    return converted.RunbookId !== undefined && typeSafeHasOwnProperty(converted, "RunbookId");
}
export function isBlueprintProcessResource(resource: IProcessResource | null | undefined): resource is NonNullable<BlueprintProcessResource> {
    if (resource === null || resource === undefined) {
        return false;
    }
    const converted = resource as BlueprintProcessResource;
    return converted.Slug !== undefined && typeSafeHasOwnProperty(converted, "Slug");
}
export function processResourcePermission(resource: IProcessResource): Permission {
    const isRunbook = isRunbookProcessResource(resource);
    return isRunbook ? Permission.RunbookEdit : Permission.ProcessEdit;
}
