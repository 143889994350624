import type { ResourceWithId } from "./resource";
import type { SensitiveValue } from ".";
export interface CreateGitCredentialResponse {
    Id: string;
}
interface GitCredentialResourceLinks {
    Self: string;
    Usage: string;
}
export enum GitCredentialAuthenticationType {
    UsernamePassword = "UsernamePassword"
}
export interface UsernamePasswordGitCredentialDetailsResource {
    Type: GitCredentialAuthenticationType.UsernamePassword;
    Username: string;
    Password: SensitiveValue;
}
export interface GitCredentialUsageProject {
    ProjectId: string;
    Slug: string;
    Name: string;
    RepositoryUrl: string;
}
export interface GitCredentialUsage {
    Projects: GitCredentialUsageProject[];
    OtherProjects: number; // Other projects using this credential that the user can not see
}
export interface GitCredentialResource extends ResourceWithId<GitCredentialResourceLinks> {
    SpaceId: string;
    Name: string;
    Description?: string;
    Details: UsernamePasswordGitCredentialDetailsResource; // Only one type for now, will add more in the future
}
