import type { AnalyticsEvent, AnalyticsEventPageProperties } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import React, { useCallback, useEffect, useState } from "react";
export function useTrackSpaceSwitcherOpenEffect(open: boolean) {
    const trackSpaceSwitcherDispatch = useTrackSpaceSwitcherInteraction();
    //We don't really want the effect to fire for the initial render as it isn't a user interaction
    //otherwise we would capture close events for something the user never did. We therefore track
    //the previous state which is initially undefined to work out whether we should dispatch an event.
    const previousIsOpen = usePrevious(open);
    React.useEffect(() => {
        if (previousIsOpen === undefined || open === previousIsOpen) {
            return;
        }
        if (open) {
            trackSpaceSwitcherDispatch(spaceSwitcherInteractionType.open);
        }
        else {
            trackSpaceSwitcherDispatch(spaceSwitcherInteractionType.close);
        }
    }, [trackSpaceSwitcherDispatch, previousIsOpen, open]);
}
export function useTrackAddNewSpace() {
    const trackSpaceSwitcherDispatch = useTrackSpaceSwitcherInteraction();
    return useCallback(() => {
        trackSpaceSwitcherDispatch(spaceSwitcherInteractionType.addNewSpace);
    }, [trackSpaceSwitcherDispatch]);
}
export function useTrackSpaceSwitcherStartedSearchEffect(searchValue: string, isOpen: boolean) {
    const trackSpaceSwitcherDispatch = useTrackSpaceSwitcherInteraction();
    const hasStartedSearching = useHasStartedSearching(searchValue, isOpen);
    React.useEffect(() => {
        if (hasStartedSearching) {
            trackSpaceSwitcherDispatch(spaceSwitcherInteractionType.searchStarted);
        }
    }, [trackSpaceSwitcherDispatch, hasStartedSearching]);
}
function useHasStartedSearching(searchValue: string, isOpen: boolean) {
    const [hasSearched, setHasSearched] = useState(searchValue !== "");
    useEffect(() => {
        setHasSearched((prev) => {
            if (isOpen) {
                return prev ? prev : searchValue !== "";
            }
            return false;
        });
    }, [isOpen, searchValue]);
    return hasSearched;
}
export function useTrackSpaceSelectedCallback() {
    const trackSpaceSwitcherInteraction = useTrackSpaceSwitcherInteraction();
    return React.useCallback(() => {
        trackSpaceSwitcherInteraction(spaceSwitcherInteractionType.spaceSelected);
    }, [trackSpaceSwitcherInteraction]);
}
function usePrevious<T>(value: T) {
    const ref = React.useRef<T | undefined>(undefined);
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
function useTrackSpaceSwitcherInteraction() {
    const trackEvent = useTrackEvent();
    const page = usePage();
    return React.useCallback((interaction: SpaceSwitcherInteractionType) => trackEvent(createSpaceSwitcherAnalyticsEvent(interaction, page)), [trackEvent, page]);
}
interface SpaceSwitcherAnalyticsEvent extends AnalyticsEvent<"Space Switcher">, AnalyticsEventPageProperties {
    Interaction: SpaceSwitcherInteractionType;
}
function createSpaceSwitcherAnalyticsEvent(interaction: SpaceSwitcherInteractionType, page: PageDefinition): SpaceSwitcherAnalyticsEvent {
    return {
        eventName: "Space Switcher",
        Interaction: interaction,
        "Page Area": page.Area,
        "Page Name": page.Name,
    };
}
export const spaceSwitcherInteractionType = {
    open: "open",
    close: "close",
    addNewSpace: "add new space",
    searchStarted: "search started",
    spaceSelected: "space selected",
} as const;
type SpaceSwitcherInteractionType = (typeof spaceSwitcherInteractionType)[keyof typeof spaceSwitcherInteractionType];
