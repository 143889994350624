import type { Client, RequestContext } from "../client";
import type { IconSvgResource, IconMetadataResource, IconApiResource } from "../resources";
export class IconRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) { }
    async getIcons(): Promise<IconSvgResource[]> {
        const allIconsLink = this.client.getSystemLink((g) => g.LogoIcons);
        const data: IconApiResource = await this.client.get<IconApiResource>(this.requestContext, allIconsLink);
        return data.icons;
    }
    async getIconMetadata(): Promise<IconMetadataResource> {
        const iconCategoriesLink = this.client.getSystemLink((g) => g.LogoIconCategories);
        return await this.client.get<IconMetadataResource>(this.requestContext, iconCategoriesLink);
    }
}
