import type { Client, RequestContext } from "../client";
import type { ListArgs } from "../repositories/basicRepository";
import type { ServerDocumentCounts } from "../resources/serverDocumentCount";
import type { ServerStatusHealthResource } from "../resources/serverStatusHealthResource";
import type { ServerStatusResource } from "../resources/serverStatusResource";
import type { ServerTimezoneResource } from "../resources/serverTimezoneResource";
import type { SystemInfoResource } from "../resources/systemInfoResource";
import type { ActivityLogElement } from "../resources/taskDetailsResource";
type LogsListArgs = {
    includeDetail: boolean;
} & ListArgs;
export class ServerStatusRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getServerStatus() {
        return this.client.get<ServerStatusResource>(this.requestContext, this.client.getLink("ServerStatus"));
    }
    getLogs(status: ServerStatusResource, args?: LogsListArgs) {
        return this.client.get<ActivityLogElement[]>(this.requestContext, status.Links["RecentLogs"], args);
    }
    getHealth(status: ServerStatusResource) {
        return this.client.get<ServerStatusHealthResource>(this.requestContext, status.Links["Health"]);
    }
    getSystemInfo(status: ServerStatusResource) {
        return this.client.get<SystemInfoResource>(this.requestContext, status.Links["SystemInfo"]);
    }
    gcCollect(status: ServerStatusResource) {
        return this.client.post(this.requestContext, status.Links["GCCollect"], status);
    }
    getDocumentCounts(status: ServerStatusResource) {
        return this.client.get<ServerDocumentCounts>(this.requestContext, status.Links["DocumentCounts"]);
    }
    getTimezones() {
        return this.client.get<ServerTimezoneResource[]>(this.requestContext, this.client.getLink("Timezones"));
    }
}
