import type { StructuredLogEvent, PropertyValues, PropertyValue } from "../types";
// There are a number of "features" of serilog like "object destructing" which we don't support
// In many cases, these either don't make sense in a javascript world (where we don't have a nominal runtime type system like C#),
// or they have little value in this code base.
// Instead, we only support a very simple message syntax, where "{PropertyName}" gets replaced by "PropertyValue".
// See the tests for to understand the supported format
export function renderLogMessage(logEvent: StructuredLogEvent): string {
    const tokens = getTokens(logEvent.messageTemplate);
    return tokens.reduce((p, c) => `${p}${getStringForToken(c, logEvent.propertyValues)}`, "");
}
function getStringForToken(token: Token, propertyValues: PropertyValues): string {
    if (token.type === "string")
        return token.value;
    if (!propertyValues.hasOwnProperty(token.propertyName)) {
        return `{${token.propertyName}}`;
    }
    return stringifyValue(propertyValues[token.propertyName]);
}
function getTokens(logMessageTemplate: string): Token[] {
    const tokens: Token[] = [];
    let currentlyProcessing: Token = { type: "string", value: "" };
    for (const templateCharacter of logMessageTemplate) {
        if (currentlyProcessing.type === "string") {
            if (templateCharacter === "{") {
                tokens.push(currentlyProcessing);
                currentlyProcessing = { type: "property", propertyName: "" };
            }
            else {
                currentlyProcessing.value += templateCharacter;
            }
        }
        else {
            if (templateCharacter === "{") {
                currentlyProcessing = { type: "string", value: `{${currentlyProcessing.propertyName}{` };
            }
            else if (templateCharacter === "}") {
                tokens.push(currentlyProcessing);
                currentlyProcessing = { type: "string", value: "" };
            }
            else {
                currentlyProcessing.propertyName += templateCharacter;
            }
        }
    }
    if (currentlyProcessing.type === "string") {
        tokens.push(currentlyProcessing);
    }
    else {
        tokens.push({ type: "string", value: `{${currentlyProcessing.propertyName}` });
    }
    return tokens;
}
function stringifyValue(propertyValue: PropertyValue): string {
    if (typeof propertyValue === "object") {
        return JSON.stringify(propertyValue);
    }
    if (propertyValue === undefined)
        return "<undefined>";
    if (propertyValue === null)
        return "<null>";
    return propertyValue.toString();
}
type Token = {
    type: "string";
    value: string;
} | {
    type: "property";
    propertyName: string;
};
