import React from "react";
import type { AnalyticSession } from "./AnalyticsSession";
const AnalyticSessionContext = React.createContext<AnalyticSession | undefined>(undefined);
interface AnalyticSessionProps {
    session: AnalyticSession | undefined;
}
export function AnalyticSessionProvider(props: React.PropsWithChildren<AnalyticSessionProps>) {
    return <AnalyticSessionContext.Provider value={props.session}>{props.children}</AnalyticSessionContext.Provider>;
}
export function useAnalyticSession(): AnalyticSession {
    const session = React.useContext(AnalyticSessionContext);
    if (!session) {
        throw new Error("The analytics session context has not been initialized. Please provide a valid analytics session first.");
    }
    return session;
}
