import type { Client, RequestContext } from "../client";
import type { ServerConfigurationResource, ServerConfigurationSettingsSetResource } from "../resources";
import { ConfigurationRepository } from "./configurationRepository";
export class ServerConfigurationRepository extends ConfigurationRepository<ServerConfigurationResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("ServerConfiguration", client, requestContext);
    }
    settings() {
        return this.client.get<ServerConfigurationSettingsSetResource[]>(this.requestContext, this.client.getLink("ServerConfigurationSettings"));
    }
}
