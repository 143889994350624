import type { LoggingSink, LogEvent } from "../types";
export function createCompositeSink(...sinks: LoggingSink[]): LoggingSink {
    return {
        receiveLogEvents(logEvents: LogEvent[]) {
            sinks.forEach((s) => s.receiveLogEvents(logEvents));
        },
        flush: () => {
            return Promise.all(sinks.map((sink) => sink.flush?.()));
        },
    };
}
