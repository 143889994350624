import type { Expand } from "@octopusdeploy/type-utils";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import URI from "urijs";
export interface QueryParam<TKey extends string, TValue> {
    name: TKey;
    // QueryParams must decide what to do when the serializedValue is not provided (i.e. undefined) rather than the framework assuming a default value
    parse: (serializedValue: string | undefined) => TValue;
    // QueryParams must decide what to do when the query param has been omitted, since the framework makes all query params optional
    serialize: (value: TValue | undefined) => string | undefined;
}
export interface ArrayQueryParam<TKey extends string, TValue> {
    name: TKey;
    // QueryParams must decide what to do when the serializedValue is not provided (i.e. undefined) rather than the framework assuming a default value
    parse: (serializedValues: string[]) => TValue;
    // QueryParams must decide what to do when the query param has been omitted, since the framework makes all query params optional
    serialize: (value: TValue | undefined) => string[];
    isArray: true;
}
// This type is used for type constraints and cannot be "unknown"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownQueryParam = QueryParam<string, any> | ArrayQueryParam<string, any>;
export function createQueryParam<TKey extends string, TValue>(paramName: TKey, parse: (serializedValue: string | undefined) => TValue, serialize: (value: TValue | undefined) => string | undefined): QueryParam<TKey, TValue> {
    return {
        name: paramName,
        parse,
        serialize,
    };
}
export function createArrayQueryParam<TKey extends string, TValue>(paramName: TKey, parse: (serializedValues: string[]) => TValue, serialize: (value: TValue | undefined) => string[]): ArrayQueryParam<TKey, TValue> {
    return {
        name: paramName,
        parse,
        serialize,
        isArray: true,
    };
}
export function stringQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, string> {
    return createQueryParam(paramName, (serializedValue) => serializedValue ?? "", (value) => (value === "" ? undefined : value));
}
/**
 * Use this query param type if you need to handle empty strings explicitly as a
 * distinct state from a missing or omitted query string value.
 * In almost all cases, we should prefer to use {@link stringQueryParam} instead.
 * @param paramName The name of the parameter
 */
export function optionalStringQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, string | undefined> {
    return createQueryParam(paramName, (serializedValue) => serializedValue, (value) => value);
}
export function stringArrayQueryParam<TKey extends string>(paramName: TKey): ArrayQueryParam<TKey, string[]> {
    return createArrayQueryParam(paramName, (serializedValues) => serializedValues, (value) => value ?? []);
}
export function numberQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, number | undefined> {
    return createQueryParam(paramName, (serializedValue) => {
        if (serializedValue === undefined)
            return undefined;
        const parsedValue = parseInt(serializedValue);
        if (Number.isNaN(parsedValue))
            return undefined;
        return parsedValue;
    }, (value) => value?.toString());
}
/**
 * Use this query param type if you need to handle an omitted parameter value explicitly as a
 * distinct state from a false value.
 * In almost all cases, we should prefer to use {@link booleanQueryParam} instead.
 * @param paramName The name of the parameter
 */
export function optionalBooleanQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, boolean | undefined> {
    return createQueryParam(paramName, (serializedValue) => (serializedValue === "true" ? true : serializedValue === "false" ? false : undefined), (value) => value?.toString());
}
/**
 * Omitted values are parsed as false. Conversely, false values are serialized such that
 * the query string value is omitted.
 * Try to define bools such that having a default false value makes sense. Otherwise, consider
 * using {@link optionalBooleanQueryParam} and subsequently initializing the query
 * param value explicitly to avoid any ambiguity for users.
 * @param paramName The name of the parameter
 */
export function booleanQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, boolean> {
    return createQueryParam(paramName, (serializedValue) => serializedValue === "true", (value) => (value ? "true" : undefined));
}
export function dateQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, Date | undefined> {
    return createQueryParam(paramName, (serializedValue) => {
        if (serializedValue === undefined)
            return undefined;
        const parsedDate = new Date(serializedValue);
        if (Number.isNaN(parsedDate.valueOf())) {
            return undefined;
        }
        return parsedDate;
    }, (value) => value?.toISOString());
}
export type QueryParamValues<QueryParams> = QueryParams extends UnknownQueryParam[] ? ParseQueryParamsArray<QueryParams> : never;
type ParseQueryParamsArray<QueryParams extends UnknownQueryParam[]> = Expand<MergeObjects<{
    [K in keyof QueryParams]: MapQueryParamToObject<QueryParams[K]>;
}>>;
type MapQueryParamToObject<Param extends UnknownQueryParam> = Param extends ArrayQueryParam<infer TKey extends string, infer TValue> ? QueryStringProperties<TKey, TValue> : Param extends QueryParam<infer TKey extends string, infer TValue> ? QueryStringProperties<TKey, TValue> : never;
type MapQueryParamToValue<Param extends UnknownQueryParam> = Param extends ArrayQueryParam<infer TKey extends string, infer TValue> ? TValue : Param extends QueryParam<infer TKey extends string, infer TValue> ? TValue : never;
type QueryStringProperties<TKey extends string, TValue> = {
    [N in TKey]: TValue;
};
type MergeObjects<T extends unknown[]> = T extends [
    infer Value
] ? Value : T extends [
    infer Value,
    ...infer Rest
] ? Value & MergeObjects<Rest> : {};
export type NewQueryParamValues<QueryParamValues> = Partial<QueryParamValues>;
type NextQueryParamValues<QueryParamValues> = NewQueryParamValues<QueryParamValues> | ((prevValue: QueryParamValues) => NewQueryParamValues<QueryParamValues>);
export type QueryParamValuesSetter<QueryParamValues> = (value: NextQueryParamValues<QueryParamValues>) => void;
type NewQueryParamValue<QueryParamValue> = QueryParamValue | undefined;
type NextQueryParamValue<QueryParamValue> = NewQueryParamValue<QueryParamValue> | ((prevValue: QueryParamValue) => NewQueryParamValue<QueryParamValue>);
export type QueryParamValueSetter<QueryParamValue> = (value: NextQueryParamValue<QueryParamValue>) => void;
export enum QueryStateMode {
    PushHistory = "PushHistory",
    ReplaceHistory = "ReplaceHistory"
}
export function useQueryStringParams<QueryStringParams extends UnknownQueryParam[]>(paramDefinitions: [
    ...QueryStringParams
], mode: QueryStateMode = QueryStateMode.PushHistory): [
    QueryParamValues<QueryStringParams>,
    QueryParamValuesSetter<QueryParamValues<QueryStringParams>>
] {
    const updateUrl = useUpdateUrl();
    const { search, pathname } = useLocation();
    const values = useMemo(() => {
        const params = new URLSearchParams(search);
        const values = paramDefinitions.reduce<Partial<QueryParamValues<QueryStringParams>>>((prev, paramDefinition) => {
            const name = paramDefinition.name;
            const parsedValue = parseQueryParameterValueFromUrl(params, paramDefinition);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            prev[name as keyof QueryParamValues<QueryStringParams>] = parsedValue;
            return prev;
        }, {});
        // We know this is a complete (non-partial) object at this point
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return values as QueryParamValues<QueryStringParams>;
    }, [paramDefinitions, search]);
    const updateQueryParamValue = useCallback((nextQueryParamValue: NextQueryParamValues<QueryParamValues<QueryStringParams>>) => {
        const url = new URI(pathname + search);
        const newParamValue = isQueryParamValuesFunction(nextQueryParamValue) ? nextQueryParamValue(values) : nextQueryParamValue;
        const serializedQueryParams = serializeQueryParams(paramDefinitions, newParamValue);
        serializedQueryParams.forEach(({ name, values }) => {
            if (values !== undefined) {
                url.setQuery(name, values);
            }
            else {
                url.removeQuery(name);
            }
        });
        updateUrl(url, mode);
    }, [paramDefinitions, pathname, updateUrl, search]);
    return [values, updateQueryParamValue];
}
export function useQueryStringParam<Param extends UnknownQueryParam>(paramDefinition: Param, mode: QueryStateMode = QueryStateMode.PushHistory): [
    MapQueryParamToValue<Param>,
    QueryParamValueSetter<MapQueryParamToValue<Param>>
] {
    const updateUrl = useUpdateUrl();
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const serializedValues = params.getAll(paramDefinition.name).sort();
    const parsedValue = parseQueryParameterValueFromUrl(params, paramDefinition);
    const updateQueryParamValue = useCallback((nextQueryParamValue: NextQueryParamValue<MapQueryParamToValue<typeof paramDefinition>>) => {
        const url = new URI(pathname + search);
        const newParamValue = isQueryParamValueFunction(nextQueryParamValue) ? nextQueryParamValue(parsedValue) : nextQueryParamValue;
        if (newParamValue === undefined) {
            url.removeQuery(paramDefinition.name);
            updateUrl(url, mode);
        }
        else {
            const newSerializedValues = serializeQueryParam(paramDefinition, newParamValue).sort();
            if (!arrayShallowEqual(serializedValues, newSerializedValues)) {
                if (newSerializedValues.length > 0) {
                    url.setQuery(paramDefinition.name, newSerializedValues);
                }
                else {
                    url.removeQuery(paramDefinition.name);
                }
                updateUrl(url, mode);
            }
        }
    }, [mode, paramDefinition, pathname, updateUrl, search, serializedValues]);
    return [parsedValue, updateQueryParamValue];
}
function arrayShallowEqual<T>(array1: T[], array2: T[]) {
    if (array1.length !== array2.length) {
        return false;
    }
    return array1.every((value, index) => value === array2[index]);
}
interface SerializedQueryParameter {
    name: string;
    values: string[];
}
export function serializeQueryParams<QueryParams extends UnknownQueryParam[]>(queryParams: [
    ...QueryParams
], newQueryValues: NewQueryParamValues<QueryParamValues<QueryParams>>): SerializedQueryParameter[] {
    return queryParams.map((queryParam) => {
        const name = queryParam.name;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const newValue = newQueryValues[name as keyof QueryParamValues<QueryParams>];
        const serializedValues = serializeQueryParam(queryParam, newValue);
        return { name, values: serializedValues };
    });
}
function serializeQueryParam<TKey extends string, TValue>(queryParam: QueryParam<TKey, TValue> | ArrayQueryParam<TKey, TValue>, newValue: TValue | undefined): string[] {
    if (isArrayQueryParam(queryParam)) {
        return queryParam.serialize(newValue);
    }
    else {
        const serializeValue = queryParam.serialize(newValue);
        return serializeValue === undefined ? [] : [serializeValue];
    }
}
function parseQueryParameterValueFromUrl<TKey extends string, TValue>(urlSearchParams: URLSearchParams, paramDefinition: QueryParam<TKey, TValue> | ArrayQueryParam<TKey, TValue>): TValue | TValue[] | undefined {
    const name = paramDefinition.name;
    if (isArrayQueryParam(paramDefinition)) {
        const paramStringValues = urlSearchParams.getAll(name);
        return paramDefinition.parse(paramStringValues);
    }
    else {
        const paramStringValue = urlSearchParams.get(name);
        return paramDefinition.parse(paramStringValue ?? undefined);
    }
}
function isArrayQueryParam<TKey extends string, TValue>(value: QueryParam<TKey, TValue> | ArrayQueryParam<TKey, TValue>): value is ArrayQueryParam<TKey, TValue> {
    return "isArray" in value && value.isArray;
}
function isQueryParamValuesFunction<T>(value: NextQueryParamValues<T>): value is (t: T) => Partial<T> {
    return typeof value === "function";
}
function isQueryParamValueFunction<T>(value: NextQueryParamValue<T>): value is (t: T) => T | undefined {
    return typeof value === "function";
}
function useUpdateUrl() {
    const { push, replace } = useHistory();
    return (url: URI, mode: QueryStateMode = QueryStateMode.PushHistory) => {
        const newUrl = url.toString();
        if (mode === QueryStateMode.PushHistory) {
            push(newUrl);
        }
        else {
            replace(newUrl);
        }
    };
}
