import type { Client, RequestContext } from "../client";
import type { SigningKeyConfigurationResource } from "../resources";
import { ConfigurationRepository } from "./configurationRepository";
export class SigningKeyConfigurationRepository extends ConfigurationRepository<SigningKeyConfigurationResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("SigningKeyConfiguration", client, requestContext);
    }
    // overrides the base method in order to not use hypermedia links
    modify(resource: SigningKeyConfigurationResource): Promise<SigningKeyConfigurationResource> {
        return this.client.update<SigningKeyConfigurationResource>(this.requestContext, this.client.getLink("SigningKeyConfiguration"), resource);
    }
}
