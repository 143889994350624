/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { every } from "lodash";
import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { PackageAcquisitionLocation } from "./packageAcquisitionLocation";
import type { SensitiveValue } from "./propertyValueResource";
import type { ResourceWithId } from "./resource";
interface FeedLinks {
    Self: string;
    SearchPackagesTemplate: string;
    SearchPackageVersionsTemplate: string;
    BuiltInStatus: string;
    Packages: string;
    SearchTemplate: string;
    VersionsTemplate: string;
    NotesTemplate: string;
}
interface BuiltInFeedLinks extends FeedLinks {
    NuGetPush: string;
    ApiPush: string;
}
export enum FeedType {
    Nuget = "NuGet",
    Docker = "Docker",
    Maven = "Maven",
    GitHub = "GitHub",
    BuiltIn = "BuiltIn",
    OctopusProject = "OctopusProject",
    Helm = "Helm",
    OciRegistry = "OciRegistry",
    AwsElasticContainerRegistry = "AwsElasticContainerRegistry",
    S3 = "S3",
    AzureContainerRegistry = "AzureContainerRegistry",
    GoogleContainerRegistry = "GoogleContainerRegistry",
    ArtifactoryGeneric = "ArtifactoryGeneric"
}
export interface DockerFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.Docker;
    ApiVersion?: any;
    RegistryPath: string;
    Name: string;
    FeedUri: string;
    Username?: any;
    Password?: SensitiveValue;
}
export interface RetryFeed extends Feed, ResourceWithId<FeedLinks> {
    DownloadAttempts: number;
    DownloadRetryBackoffSeconds: number;
}
export interface NugetFeedResource extends RetryFeed {
    FeedType: FeedType.Nuget;
    EnhancedMode: boolean;
    Name: string;
    FeedUri: string;
    Username?: string;
    Password?: SensitiveValue;
}
export interface HelmFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.Helm;
    Name: string;
    FeedUri: string;
    Username?: string;
    Password?: SensitiveValue;
}
export interface OciRegistryFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.OciRegistry;
    Name: string;
    FeedUri: string;
    Username?: string;
    Password?: SensitiveValue;
}
export interface ArtifactoryGenericFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.ArtifactoryGeneric;
    Name: string;
    FeedUri: string;
    Repository: string;
    LayoutRegex: string;
    Password?: SensitiveValue;
    Username?: string;
}
export interface MavenFeedResource extends RetryFeed {
    FeedType: FeedType.Maven;
    Name: string;
    FeedUri: string;
    Username?: string;
    Password?: SensitiveValue;
}
export interface GitHubFeedResource extends RetryFeed {
    FeedType: FeedType.GitHub;
    Name: string;
    FeedUri: string;
    Username?: string;
    Password?: SensitiveValue;
}
export interface Feed extends ResourceWithSlug {
    FeedType: FeedType;
    PackageAcquisitionLocationOptions?: PackageAcquisitionLocation[];
    SpaceId?: string;
    LastModifiedOn?: string | null;
    LastModifiedBy?: string | null;
}
export interface AwsElasticContainerRegistryFeedResource extends RetryFeed {
    FeedType: FeedType.AwsElasticContainerRegistry;
    Name: string;
    FeedUri: string;
    Region: string;
    AccessKey: string;
    SecretKey: SensitiveValue;
}
export interface AzureContainerRegistryFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.AzureContainerRegistry;
    ApiVersion?: any;
    RegistryPath: string;
    Name: string;
    FeedUri: string;
    Username?: any;
    Password?: SensitiveValue;
}
export interface GoogleContainerRegistryFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.GoogleContainerRegistry;
    ApiVersion?: any;
    RegistryPath: string;
    Name: string;
    FeedUri: string;
    Username?: any;
    Password?: SensitiveValue;
}
export interface S3FeedResource extends RetryFeed {
    FeedType: FeedType.S3;
    Name: string;
    FeedUri: string;
    AccessKey: string;
    SecretKey: SensitiveValue;
    UseMachineCredentials: boolean;
}
export interface BuiltInFeedResource extends Feed, ResourceWithId<BuiltInFeedLinks> {
    FeedType: FeedType.BuiltIn;
    Name: string;
    IsBuiltInRepoSyncEnabled: boolean;
    DeleteUnreleasedPackagesAfterDays?: number;
}
export interface OctopusProjectFeedResource extends Feed, ResourceWithId<FeedLinks> {
    FeedType: FeedType.OctopusProject;
    Name: string;
}
export type ExternalFeedResource = NugetFeedResource | DockerFeedResource | MavenFeedResource | GitHubFeedResource | HelmFeedResource | OciRegistryFeedResource | AwsElasticContainerRegistryFeedResource | S3FeedResource | AzureContainerRegistryFeedResource | GoogleContainerRegistryFeedResource | ArtifactoryGenericFeedResource;
export type FeedResource = ExternalFeedResource | BuiltInFeedResource | OctopusProjectFeedResource;
function feedTypeCanSearchEmpty(feed: FeedType): boolean {
    return ![FeedType.Docker, FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.GoogleContainerRegistry, FeedType.Maven, FeedType.GitHub].includes(feed);
}
function feedTypeSupportsExtraction(feed: FeedType): boolean {
    // Container images can not be extracted
    return ![FeedType.Docker, FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.GoogleContainerRegistry].includes(feed);
}
function isOctopusProjectFeed(feed: FeedType): boolean {
    return (feed as string) === "OctopusProject";
}
function containerRegistryFeedTypes(): FeedType[] {
    return [FeedType.Docker, FeedType.AwsElasticContainerRegistry, FeedType.AzureContainerRegistry, FeedType.GoogleContainerRegistry];
}
function isContainerImageRegistry(feed: FeedType): boolean {
    return containerRegistryFeedTypes().includes(feed);
}
function helmChartFeedTypes(): FeedType[] {
    return [FeedType.Helm, FeedType.OciRegistry];
}
function isHelmChartFeed(feed: FeedType): boolean {
    return helmChartFeedTypes().includes(feed);
}
const getFeedTypeLabel = (feedType?: FeedType[]) => {
    const requiresContainerImageRegistryFeed = feedType && feedType.length >= 1 && every(feedType, (f) => isContainerImageRegistry(f));
    const requiresHelmChartFeed = feedType && feedType.length === 1 && every(feedType, (f) => isHelmChartFeed(f));
    if (requiresContainerImageRegistryFeed) {
        return "container registry";
    }
    if (requiresHelmChartFeed) {
        return "Helm Chart Repository";
    }
    return "Package";
};
export { feedTypeCanSearchEmpty, feedTypeSupportsExtraction, isOctopusProjectFeed, containerRegistryFeedTypes, isContainerImageRegistry, isHelmChartFeed, getFeedTypeLabel };
