import { routeParam, routeTemplate, spaceIdPlaceholder } from "../RouteTemplate";
import { createLegacyRoute } from "./LegacyRoute";
export const allLegacyRoutes = {
    newProject: createLegacyRoute(routeTemplate `/projects/new`, []),
    v3Certificates: createLegacyRoute(routeTemplate `${spaceIdPlaceholder}/configuration/certificates`, []),
    v3UserInvites: createLegacyRoute(routeTemplate `${spaceIdPlaceholder}/configuration/users/invite`, []),
    v3TestPermissions: createLegacyRoute(routeTemplate `${spaceIdPlaceholder}/configuration/teams/test`, []),
    v3CreateCertificate: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/certificates/new`, []),
    v3CreateFeed: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/feeds/new`, []),
    v3CreateLifecycle: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/lifecycles/new`, []),
    v3CreateScript: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/scripts/new`, []),
    v3CreateTagSet: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/tagsets/new`, []),
    v3CreateVariableSet: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/variables/new`, []),
    V3Environments: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/environments`, []),
    V3Accounts: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/accounts`, []),
    V3Machines: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/machines`, []),
    tenantRoot: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/tenants/${routeParam("tenantId")}`, []),
    deploymentTargetRoot: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/infrastructure/machines/${routeParam("machineId", /Machines-[0-9]+/)}`, []),
    workerMachineRoot: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/infrastructure/workers/${routeParam("machineId", /Workers-[0-9]+/)}`, []),
    newTenant: createLegacyRoute(routeTemplate `/tenants/new`, []),
    communityTemplateDetails: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/library/steptemplates/community/${routeParam("templateId")}/details`, []),
    runbookSnapshotRunCreateWithGoal: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/operations/runbooks/${routeParam("runbookId")}/snapshots/${routeParam("runbookSnapshotId")}/runs/create/${routeParam("goal")}`, []),
    runbookSnapshotRunWithGoalAndPreviousId: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/operations/runbooks/${routeParam("runbookId")}/snapshots/${routeParam("runbookSnapshotId")}/runs/create/${routeParam("goal")}/${routeParam("previousId")}`, []),
    newProjectProcessStep: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/deployments/process/step/new/${routeParam("actionType")}/${routeParam("template")}`, []),
    newProjectProcessStepInSpecificStep: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/deployments/process/step/${routeParam("stepId")}/new/${routeParam("actionType")}/${routeParam("template")}`, []),
    specificProjectProcessStep: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/deployments/process/step/${routeParam("stepId")}/${routeParam("reloadKey")}`, []),
    projectProcessStepTemplates: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/deployments/process/steptemplates`, []),
    projectProcessChildStepTemplates: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/deployments/process/childsteptemplates/${routeParam("parentStepId")}`, []),
    manageConsole: createLegacyRoute(routeTemplate `${spaceIdPlaceholder}/manage/console`, []),
    projectCreateDeploymentWithGoal: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/releases/${routeParam("releaseVersion")}/deployments/create/${routeParam("goal")}`, []),
    projectCreateDeploymentWithGoalAndPreviousId: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/releases/${routeParam("releaseVersion")}/deployments/create/${routeParam("goal")}/${routeParam("previousId")}`, []),
    projectCreateDeploymentWithGoalAndPreviousIdAndTenants: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/releases/${routeParam("releaseVersion")}/deployments/create/${routeParam("goal")}/${routeParam("previousId")}/${routeParam("tenantIds")}`, []),
    deploymentDetailsPage: createLegacyRoute(routeTemplate `/${routeParam("spaceId")}/projects/${routeParam("projectSlug")}/releases/${routeParam("releaseVersion")}/deployments/${routeParam("deploymentId")}`, []),
};
