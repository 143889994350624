import type { Client, RequestContext } from "../client";
import type { LicenseResource, LicenseStatusResource, LicenseUsageResource } from "../resources";
export class LicenseRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) { }
    getCurrent() {
        return this.client.get<LicenseResource>(this.requestContext, this.client.getLink("CurrentLicense"));
    }
    modifyCurrent(resource: LicenseResource): Promise<LicenseResource> {
        return this.client.update(this.requestContext, resource.Links.Self, resource);
    }
    getCurrentStatus() {
        return this.client.get<LicenseStatusResource>(this.requestContext, this.client.getLink("CurrentLicenseStatus"));
    }
    getCurrentUsage() {
        return this.client.get<LicenseUsageResource>(this.requestContext, this.client.getLink("CurrentLicenseUsage"));
    }
}
