export interface MachineUpdatePolicy {
    CalamariUpdateBehavior: CalamariUpdateBehavior;
    TentacleUpdateBehavior: TentacleUpdateBehavior;
    KubernetesAgentUpdateBehavior: KubernetesAgentUpdateBehavior;
    TentacleUpdateAccountId: string;
}
export enum CalamariUpdateBehavior {
    UpdateOnDeployment = "UpdateOnDeployment",
    UpdateOnNewMachine = "UpdateOnNewMachine",
    UpdateAlways = "UpdateAlways"
}
export enum TentacleUpdateBehavior {
    NeverUpdate = "NeverUpdate",
    Update = "Update"
}
export enum KubernetesAgentUpdateBehavior {
    NeverUpdate = "NeverUpdate",
    Update = "Update"
}
