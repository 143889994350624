/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import URI from "urijs";
import URITemplate from "urijs/src/URITemplate";
// The Resolver is used to take URI's like "/api/foo/bar" and turn them into
// fully qualified URI's depending on the Octopus
// server we are talking to (e.g.,
// http://your-octopus/api/foo/bar or perhaps even, http://your-octopus/vdir1/vdir2/api/foo/bar).
// Only very specific types are supported, so let's constrain what can be passed in to stop people making mistakes
type RouteParameterSimpleTypes = string | number | boolean;
type RouteParameter = RouteParameterSimpleTypes | RouteParameterSimpleTypes[] | undefined | null;
type RouteArgLookup = {
    [key: string]: RouteParameter;
};
export type RouteArgs<TOther extends RouteArgLookup = RouteArgLookup> = Pick<TOther, keyof TOther> | TOther;
export class Resolver {
    private baseUri: URI;
    constructor(baseUri: string) {
        this.baseUri = this.parseBaseUri(baseUri);
    }
    parseBaseUri(baseUri: string): URI {
        const uri = URI(baseUri);
        uri.path(this.trimApiSegments(uri.path()));
        return uri;
    }
    resolve(path: string, uriTemplateParameters?: RouteArgs): string {
        if (!path) {
            throw new Error("The link is not set to a value");
        }
        const fullyQualifiedUri = this.getFullyQualifiedUri(path);
        const template = new URITemplate(fullyQualifiedUri);
        const result = (template as any).expand(uriTemplateParameters || {});
        return result;
    }
    getFullyQualifiedUri(path: string): string {
        const pathUri = URI(path);
        // this path is absolute
        if (pathUri.hostname()) {
            return path;
        }
        // the path is relative to baseUri, but already has virtual directory segments
        if (this.alreadyContainsVirtualDirectorySegments(pathUri)) {
            return `${this.origin(this.baseUri)}/${this.trimPrefix(path)}`;
        }
        // the path is relative to baseUri
        const base = this.baseUri.valueOf() + (this.baseUri.valueOf().endsWith("/") ? "" : "/");
        return base + this.trimPrefix(path);
    }
    trimApiSegments(uri: string): string {
        const lastIndexOfMandatorySegment = uri.lastIndexOf("/api");
        if (lastIndexOfMandatorySegment >= 0) {
            return uri.substring(0, lastIndexOfMandatorySegment);
        }
        return uri;
    }
    alreadyContainsVirtualDirectorySegments(uri: URI): boolean {
        return uri.pathname().startsWith(this.baseUri.pathname());
    }
    trimPrefix(path: string): string {
        return path.replace(/~\/|\//, "");
    }
    // this can be replaced by URI.origin() in later versions of urijs
    origin(uri: URI): string {
        return uri.scheme() + "://" + uri.authority();
    }
}
