import type { Client, RequestContext } from "../client";
import type { RunbookRunResource } from "../resources";
import type { TaskState } from "../resources/taskState";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type RunbookRunListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    runbooks?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;
export class RunbookRunRepository extends BasicRepository<RunbookRunResource, RunbookRunResource, RunbookRunListArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("RunbookRuns", client, requestContext);
    }
}
