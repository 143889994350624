import type { Client, RequestContext } from "../client";
import type { ConnectivityCheckResponse } from "../resources/index";
export class DynamicFormRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) { }
    checkConnectivity(url: string, values: {
        [key: string]: unknown;
    }): Promise<ConnectivityCheckResponse> {
        return this.client.post(this.requestContext, url, values);
    }
}
