import type { CompleteRoute } from "../CompleteRoute";
import { createCompleteRoute } from "../CompleteRoute";
import type { RouteTemplate } from "../RouteTemplate";
import type { UnknownQueryParam } from "../query/QueryStringParam";
export type LegacyRoute<RouteParams, QueryParams extends UnknownQueryParam[]> = CompleteRoute<RouteParams, QueryParams>;
export function createLegacyRoute<RouteParams, QueryParams extends UnknownQueryParam[]>(routeTemplate: RouteTemplate<RouteParams>, queryParameters: [
    ...QueryParams
]): LegacyRoute<RouteParams, QueryParams> {
    return createCompleteRoute(routeTemplate, queryParameters);
}
