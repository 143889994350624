import type { AnalyticsEventPageProperties, AnalyticsEvent } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import React, { useCallback } from "react";
export function useTrackProjectSelectedCallback() {
    const trackProjectSwitcherInteraction = useTrackProjectSwitcherInteraction();
    return useCallback(() => {
        trackProjectSwitcherInteraction(projectSwitcherInteraction.projectSelected);
    }, [trackProjectSwitcherInteraction]);
}
export function useTrackProjectSearchStartedCallback() {
    const trackProjectSwitcherInteraction = useTrackProjectSwitcherInteraction();
    const [hasTrackedInteraction, setHasTrackedInteraction] = React.useState(false);
    return useCallback(() => {
        if (!hasTrackedInteraction) {
            trackProjectSwitcherInteraction(projectSwitcherInteraction.searchStarted);
            setHasTrackedInteraction(true);
        }
    }, [trackProjectSwitcherInteraction, hasTrackedInteraction]);
}
export function useTrackProjectSwitcherInteraction() {
    const trackEvent = useTrackEvent();
    const page = usePage();
    return useCallback((interaction: ProjectSwitcherInteractionType) => trackEvent(createProjectSwitcherEvent(interaction, page)), [page, trackEvent]);
}
export const projectSwitcherInteraction = {
    open: "open",
    close: "close",
    searchStarted: "search started",
    projectSelected: "project selected",
} as const;
type ProjectSwitcherInteractionType = (typeof projectSwitcherInteraction)[keyof typeof projectSwitcherInteraction];
interface ProjectSwitcherAnalyticsEvent extends AnalyticsEvent<"Project Switcher">, AnalyticsEventPageProperties {
    Interaction: ProjectSwitcherInteractionType;
}
function createProjectSwitcherEvent(interaction: ProjectSwitcherInteractionType, page: PageDefinition): ProjectSwitcherAnalyticsEvent {
    return {
        eventName: "Project Switcher",
        Interaction: interaction,
        "Page Area": page.Area,
        "Page Name": page.Name,
    };
}
