import type { Client, RequestContext } from "../client";
import type { ProjectExportRequest } from "../resources/projectExportRequest";
import type { ProjectExportResponse } from "../resources/projectExportResponse";
import type { ProjectImportFile } from "../resources/projectImportFile";
import type { ProjectImportFileListResponse } from "../resources/projectImportFileListResponse";
import type { ProjectImportPreviewRequest } from "../resources/projectImportPreviewRequest";
import type { ProjectImportPreviewResponse } from "../resources/projectImportPreviewResponse";
import type { ProjectImportRequest } from "../resources/projectImportRequest";
import type { ProjectImportResponse } from "../resources/projectImportResponse";
export class ImportExportActions {
    protected client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    export(exportRequest: ProjectExportRequest): Promise<ProjectExportResponse> {
        return this.client.post(this.requestContext, this.client.getLink("ExportProjects"), exportRequest);
    }
    import(importRequest: ProjectImportRequest): Promise<ProjectImportResponse> {
        return this.client.post(this.requestContext, this.client.getLink("ImportProjects"), importRequest);
    }
    preview(importRequest: ProjectImportPreviewRequest): Promise<ProjectImportPreviewResponse> {
        return this.client.post(this.requestContext, this.client.getLink("ProjectImportPreview"), importRequest);
    }
    files(): Promise<ProjectImportFileListResponse> {
        return this.client.get(this.requestContext, this.client.getLink("ProjectImportFiles"));
    }
    upload(pkg: File): Promise<ProjectImportFile> {
        const fd = new FormData();
        fd.append("fileToUpload", pkg);
        return this.client.post<ProjectImportFile>(this.requestContext, this.client.getLink("ProjectImportFiles"), fd);
    }
}
