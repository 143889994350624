/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Client, RequestContext } from "../client";
import type { PermissionDescriptions } from "../resources/permissionDescriptions";
export class PermissionDescriptionRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    all(): Promise<PermissionDescriptions> {
        return this.client.get(this.requestContext, this.client.getLink("PermissionDescriptions"), null!);
    }
}
