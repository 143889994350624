import type { LoggingSink, LogEvent } from "../types";
import { createCompositeSink } from "./compositeSink";
export interface DynamicSink extends LoggingSink {
    attachSink(sinkToAttach: LoggingSink): RemoveSink;
}
export function createDynamicSink(): DynamicSink {
    let sinks: LoggingSink[] = [];
    return {
        attachSink(sinkToAttach: LoggingSink) {
            sinks = [...sinks, sinkToAttach];
            return () => {
                sinks = sinks.filter((s) => s !== sinkToAttach);
            };
        },
        receiveLogEvents(logEvents: LogEvent[]) {
            createCompositeSink(...sinks).receiveLogEvents(logEvents);
        },
        flush: () => {
            return Promise.all(sinks.map((sink) => sink.flush?.()));
        },
    };
}
export type RemoveSink = () => void;
