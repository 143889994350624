import type { RouteTemplate } from "./RouteTemplate";
import { isRouteParameter, isSpaceIdPlaceholder } from "./RouteTemplate";
import type { Url } from "./Url";
import type { NewQueryParamValues, QueryParamValues, UnknownQueryParam } from "./query/QueryStringParam";
import { serializeQueryParams } from "./query/QueryStringParam";
// This is a _complete_ route, not a partial route. This will take you to a page or to a location that can perform redirects.
export interface CompleteRoute<RouteParameters, QueryParameters extends UnknownQueryParam[]> {
    template: RouteTemplate<RouteParameters>;
    queryParameters: QueryParameters;
    generateUrl: OptionalParamsIfEmpty<(routeParams: RouteParameters, queryParams?: NeverIfEmpty<NewQueryParamValues<QueryParamValues<QueryParameters>>>) => Url>;
    getDebugString: () => string;
}
type NeverIfEmpty<T> = keyof T extends never ? never : T;
// A common case is a route with no parameters. It is more ergonomic to omit the params object in this scenario
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OptionalParamsIfEmpty<T> = T extends (routeParams: infer RP, queryParams?: infer QP) => Url ? ({} extends RP ? (queryParams?: QP) => Url : T) : T;
export function createCompleteRoute<RouteParameters, QueryParams extends UnknownQueryParam[]>(template: RouteTemplate<RouteParameters>, queryParamDefinitions: [
    ...QueryParams
]): CompleteRoute<RouteParameters, QueryParams> {
    const completeRoute: CompleteRoute<RouteParameters, QueryParams> = {
        template,
        generateUrl,
        queryParameters: queryParamDefinitions,
        getDebugString,
    };
    return completeRoute;
    function generateUrl(routeParams: RouteParameters, queryParams?: NewQueryParamValues<QueryParamValues<QueryParams>>): Url;
    function generateUrl(queryParams?: NewQueryParamValues<QueryParamValues<QueryParams>>): Url;
    function generateUrl(routeParamsOrQueryParams?: RouteParameters | NewQueryParamValues<QueryParamValues<QueryParams>>, queryParams?: NewQueryParamValues<QueryParamValues<QueryParams>>): Url {
        if (isRouteParams(routeParamsOrQueryParams)) {
            return generateUrlInternal(routeParamsOrQueryParams, queryParams);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return generateUrlInternal({} as RouteParameters, routeParamsOrQueryParams);
        }
    }
    function generateUrlInternal(routeParams: RouteParameters, queryParams?: NewQueryParamValues<QueryParamValues<QueryParams>>) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const emptyQueryParams = {} as QueryParamValues<QueryParams>;
        return {
            resolveWithSpaceId(currentSpaceId: string | null) {
                return `${getRoute()}${getQueryString()}`;
                function getRoute() {
                    return template
                        .map((part) => {
                        if (typeof part === "string")
                            return part;
                        if (isRouteParameter(part)) {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            return encodeURIComponent(routeParams[part.name] as string);
                        }
                        if (currentSpaceId) {
                            return `/${currentSpaceId}`;
                        }
                        return "";
                    })
                        .join("");
                }
                function getQueryString() {
                    return generateQueryString(queryParamDefinitions, queryParams ?? emptyQueryParams);
                }
            },
        };
    }
    function isRouteParams(routeParamsOrQueryParams?: RouteParameters | NewQueryParamValues<QueryParamValues<QueryParams>>): routeParamsOrQueryParams is RouteParameters {
        return template.some((exp) => typeof exp !== "string" && exp.type === "parameter");
    }
    function getDebugString(): string {
        const routeString = template
            .map((part) => {
            if (typeof part === "string") {
                return part;
            }
            if (isSpaceIdPlaceholder(part)) {
                return "/{spaceId}";
            }
            return `{${part.name}}`;
        })
            .join("");
        const queryStringParameters = queryParamDefinitions.map((p) => p.name);
        const queryStringSummaryString = queryStringParameters.length > 0 ? `?{${queryStringParameters.join(",")}}` : "";
        return `${routeString}${queryStringSummaryString}`;
    }
}
export function generateQueryString<QueryParams extends UnknownQueryParam[]>(queryParameter: [
    ...QueryParams
], queryValues: NewQueryParamValues<QueryParamValues<QueryParams>>): string {
    const serializedQueryParams = serializeQueryParams(queryParameter, queryValues);
    const queryParameterKeyValues = serializedQueryParams.flatMap(({ name, values }) => {
        return values.map((value) => [name, value]);
    });
    const urlSearchParams = new URLSearchParams(queryParameterKeyValues);
    // Sorting keeps things more stable and deterministic
    urlSearchParams.sort();
    const queryParameterString = urlSearchParams.toString();
    return queryParameterString.length > 0 ? `?${queryParameterString}` : "";
}
