import type { Client, RequestContext } from "../client";
export type InstallationHistoryResource = {
    Version: string;
    InstalledAt: string;
};
export class InstallationHistoryRepository {
    constructor(private readonly client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getInstallationHistory(): Promise<InstallationHistoryResource[]> {
        return this.client.get<InstallationHistoryResource[]>(this.requestContext, `~/bff/installationhistory`);
    }
}
