import { useDropdownButton } from "@octopusdeploy/design-system-components";
import { useExperimentalQuery, useOctopusClient } from "@octopusdeploy/octopus-react-client";
import type { LicenseStatusResource, SpaceResource, UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import { useOctopusSessionPermissions, useOctopusSessionUser } from "@octopusdeploy/session";
import React, { useMemo } from "react";
import type { SpaceCreationRestrictionValue } from "./ControlledSpaceSwitcher";
import { ControlledSpaceSwitcher, SpaceCreationRestriction } from "./ControlledSpaceSwitcher";
export interface SpaceSwitcherProps {
    currentSpace: SpaceResource | undefined;
    onNewSpaceRequested: () => void;
    onUnlockMoreSpacesRequested: () => void;
    IO?: SpaceSwitcherIO;
}
export interface SpaceSwitcherIO {
    getCurrentLicenseStatus: (repository: Repository) => Promise<LicenseStatusResource>;
    getCurrentUsersSpaces: (repository: Repository, currentUser: UserResource) => Promise<SpaceResource[]>;
}
const spaceSwitcherIO: SpaceSwitcherIO = {
    getCurrentLicenseStatus: (repository) => repository.Licenses.getCurrentStatus(),
    getCurrentUsersSpaces: (repository, currentUser) => repository.Users.getSpaces(currentUser),
};
export function SpaceSwitcher({ currentSpace, onNewSpaceRequested, onUnlockMoreSpacesRequested, IO = spaceSwitcherIO }: SpaceSwitcherProps) {
    const [currentError, setCurrentError] = React.useState<Error>();
    const dropdownMenuState = useDropdownButton({ trapFocus: true, dropdownAriaRole: "dialog" });
    const repository = useSpaceSwitcherRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const { refetch: refetchSpaces, spaces, isLoading, error } = useSpaces(IO);
    const { data: licenseStatus } = useExperimentalQuery(() => IO.getCurrentLicenseStatus(repository), [IO, repository]);
    const hasSpaceCreatePermission = currentPermissions.scopeToSpaceAndSystem(currentSpace?.Id ?? null).isAuthorized({
        permission: Permission.SpaceCreate,
    });
    const isWithinLicenseLimit = licenseStatus ? spacesIsWithinLicenceLimit(licenseStatus) : false;
    const restriction: SpaceCreationRestrictionValue | undefined = !hasSpaceCreatePermission ? SpaceCreationRestriction.NoPermission : !isWithinLicenseLimit ? SpaceCreationRestriction.LicenseLimitReached : undefined;
    React.useEffect(() => {
        setCurrentError(dropdownMenuState.isOpen ? error : undefined);
    }, [error, dropdownMenuState.isOpen]);
    const clearCurrentError = () => setCurrentError(undefined);
    return (<ControlledSpaceSwitcher currentSpace={currentSpace} spaces={spaces ?? []} isLoading={isLoading} onNewSpaceRequested={onNewSpaceRequested} onUnlockMoreSpacesRequested={onUnlockMoreSpacesRequested} onFetchSpacesRequested={refetchSpaces} dropdownButtonState={dropdownMenuState} spaceCreationRestriction={restriction} error={currentError} onCloseError={clearCurrentError}/>);
}
SpaceSwitcher.Controlled = ControlledSpaceSwitcher;
function spacesIsWithinLicenceLimit(licenseStatus: LicenseStatusResource): boolean {
    const spacesLimitStatus = licenseStatus.Limits.find((l) => l.Name === "Spaces");
    return spacesLimitStatus ? spacesLimitStatus.CurrentUsage < spacesLimitStatus.EffectiveLimit : false;
}
function useSpaces(IO: SpaceSwitcherIO) {
    const repository = useSpaceSwitcherRepository();
    const currentUser = useOctopusSessionUser();
    const { refetch, data, isLoading, error } = useExperimentalQuery(() => IO.getCurrentUsersSpaces(repository, currentUser), [IO, currentUser, repository]);
    const spaces = useMemo(() => {
        if (!data) {
            return undefined;
        }
        return [...data].sort((spaceA, spaceB) => {
            const nameA = spaceA.Name.toLowerCase();
            const nameB = spaceB.Name.toLowerCase();
            if (nameA < nameB)
                return -1;
            if (nameB < nameA)
                return 1;
            return 0;
        });
    }, [data]);
    return { refetch, spaces, isLoading, error };
}
function useSpaceSwitcherRepository() {
    const client = useOctopusClient();
    return useMemo(() => new Repository(client, {
        abortSignal: new AbortController().signal,
        correlationContext: { component: "SpaceSwitcher" },
    }), [client]);
}
