import type { UserIdentityMetadataResource } from "../authentication";
import type { Client, RequestContext } from "../client";
import type { UserAuthenticationResource } from "../resources/userAuthenticationResource";
export class UserIdentityMetadataRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    all(): Promise<UserIdentityMetadataResource> {
        return this.client.get(this.requestContext, this.client.getLink("UserIdentityMetadata"));
    }
    authenticationConfiguration(userId: string | null) {
        return this.client.get<UserAuthenticationResource>(this.requestContext, this.client.getLink("UserAuthentication"), { userId });
    }
}
