import type { Client, RequestContext } from "../client";
import type { UserPermissionSetResource, UserResource } from "../resources";
import { convertToSpacePartitionParameters } from "./mixedScopeBaseRepository";
export class UserPermissionRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getAllPermissions(user: UserResource, includeSystem: boolean) {
        return this.client.get<UserPermissionSetResource>(this.requestContext, user.Links["Permissions"], convertToSpacePartitionParameters("all", includeSystem));
    }
    getPermissionsForCurrentSpaceContext(user: UserResource, includeSystem: boolean) {
        return this.client.get<UserPermissionSetResource>(this.requestContext, user.Links["Permissions"], convertToSpacePartitionParameters(this.client.spaceId, includeSystem));
    }
    getPermissionsConfigurationForAllParitions(user: UserResource, includeSystem: boolean) {
        return this.client.get<UserPermissionSetResource>(this.requestContext, user.Links["PermissionsConfiguration"], convertToSpacePartitionParameters("all", includeSystem));
    }
    getPermissionsConfigurationForCurrentSpaceContext(user: UserResource, includeSystem: boolean) {
        return this.client.get<UserPermissionSetResource>(this.requestContext, user.Links["PermissionsConfiguration"], convertToSpacePartitionParameters(this.client.spaceId, includeSystem));
    }
}
