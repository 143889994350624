const StringProperties = {
    "Octopus.Action.AwsAccount.Variable": "",
    "Octopus.Action.AwsAccount.UseInstanceRole": "",
    "Octopus.Action.Aws.AssumeRole": "",
    "Octopus.Action.Aws.AssumedRoleArn": "",
    "Octopus.Action.Aws.AssumedRoleSession": "",
    "Octopus.Action.Aws.AssumeRoleSessionDurationSeconds": "",
    "Octopus.Action.Aws.AssumeRoleExternalId": "",
    "Octopus.Action.Aws.Region": "",
};
export const AllAWSScriptProperties = {
    ...StringProperties,
};
export type AWSScriptProperties = {
    [P in keyof typeof StringProperties]: string;
};
