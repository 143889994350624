interface PageIdOptions {
    area: string;
    id: string;
    name: string | null;
}
export function pageId(options: PageIdOptions): PageDefinition {
    const { area, id, name } = options;
    return {
        Area: area,
        Id: id,
        Name: name ? name : id,
    };
}
// This helps us identify each page in a human-friendly way for our reporting/customer-feedback tools.
export interface PageDefinition {
    Area: string; // Name describing the area of this page. E.g. Library.
    Id: string; // Short ID representing a given page. E.g. LibraryTenantTagSetCreate"
    Name: string; // Human-readable name describing the page. E.g. "Add Tenant Tag Set"
}
