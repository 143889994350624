import type { Client, RequestContext } from "../client";
import type { GitRefResource, ProjectResource } from "../resources";
import { HasVariablesInGit } from "../resources";
import type { ModifyProjectVariablesCommand, VariableSetResource } from "../resources/variableSetResource";
export class ProjectVariablesRepository {
    readonly resourceLink = "Variables";
    readonly sensitiveResourceLink = "SensitiveVariables";
    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly gitRef: GitRefResource | undefined, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    resolveResourceLink() {
        if (HasVariablesInGit(this.project.PersistenceSettings) && this.gitRef !== undefined) {
            return this.gitRef.Links[this.resourceLink];
        }
        return this.project.Links[this.resourceLink];
    }
    resolveSensitiveResourceLink() {
        if (HasVariablesInGit(this.project.PersistenceSettings)) {
            return this.project.Links[this.sensitiveResourceLink];
        }
        throw "This should only be used for projects with Git variables";
    }
    get(): Promise<VariableSetResource> {
        return this.client.get(this.requestContext, this.resolveResourceLink());
    }
    getSensitive(): Promise<VariableSetResource> {
        return this.client.get(this.requestContext, this.resolveSensitiveResourceLink());
    }
    modify(projectVariables: ModifyProjectVariablesCommand): Promise<VariableSetResource> {
        const link = projectVariables.Links.Self;
        return this.client.update(this.requestContext, link, projectVariables).then(() => this.client.get(this.requestContext, link));
    }
}
