export interface ConnectTenantEnvironments {
    TenantId: string;
    Environments: string[];
}
export interface ConnectTenantsToProjectRequest {
    TenantEnvironments: ConnectTenantEnvironments[];
}
export interface GetBulkTenantConnectionTaskBffResponse {
    Tasks: ConnectTenantsToProjectTaskResource[];
}
export interface ConnectTenantsToProjectTaskResource {
    Id: string;
    ConnectionsCompleted: number;
    ConnectionsRequested: number;
    ConnectionsFailed: number;
    ConnectionsRemaining: number;
    State: BusinessProcessState;
    LastModified: string;
}
export enum BusinessProcessState {
    InProgress = "InProgress",
    Completed = "Completed"
}
