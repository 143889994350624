import type { Client, RequestContext } from "../client";
export type ServiceAccountOidcIdentityResource = {
    Id: string;
    ServiceAccountId: string;
    Name: string;
    Issuer: string;
    Subject: string;
    Audience?: string | null;
};
export type GetServiceAccountOidcIdentitiesRequestV1 = {
    serviceAccountId: string;
    skip: number;
    take: number;
};
export type GetServiceAccountOidcIdentitiesResponseV1 = {
    ServerUrl: string;
    ExternalId: string;
    OidcIdentities: Array<ServiceAccountOidcIdentityResource>;
    Count: number;
};
export type DeleteServiceAccountOidcIdentityCommandV1 = {
    serviceAccountId: string;
    id: string;
};
export type ModifyServiceAccountOidcIdentityCommandV1 = {
    serviceAccountId: string;
    id: string;
    name: string;
    issuer: string;
    subject: string;
    audience?: string;
};
export type CreateServiceAccountOidcIdentityCommandV1 = {
    serviceAccountId: string;
    name: string;
    issuer: string;
    subject: string;
    audience?: string;
};
export type CreateServiceAccountOidcIdentityResponseV1 = {
    id: string;
};
export class ServiceAccountOidcIdentitiesRepository {
    protected client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getOidcIdentities(request: GetServiceAccountOidcIdentitiesRequestV1): Promise<GetServiceAccountOidcIdentitiesResponseV1> {
        return this.client.get<GetServiceAccountOidcIdentitiesResponseV1>(this.requestContext, "~/api/serviceaccounts/{serviceAccountId}/oidcidentities/v1{?skip,take}", request);
    }
    delete(command: DeleteServiceAccountOidcIdentityCommandV1): Promise<unknown> {
        return this.client.del(this.requestContext, "~/api/serviceaccounts/{serviceAccountId}/oidcidentities/{id}/v1", null, command);
    }
    modify(command: ModifyServiceAccountOidcIdentityCommandV1): Promise<unknown> {
        return this.client.put(this.requestContext, "~/api/serviceaccounts/{serviceAccountId}/oidcidentities/{id}/v1", command, command);
    }
    create(command: CreateServiceAccountOidcIdentityCommandV1): Promise<CreateServiceAccountOidcIdentityResponseV1> {
        return this.client.post(this.requestContext, "~/api/serviceaccounts/{serviceAccountId}/oidcidentities/create/v1", command, command);
    }
}
