import type { Client, RequestContext } from "../client";
import type { DefectResource } from "../resources/defectResource";
import type { ReleaseResource, ResourceCollection } from "../resources/index";
export class DefectRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    all(release: ReleaseResource) {
        return this.client.get<ResourceCollection<DefectResource>>(this.requestContext, release.Links["Defects"]);
    }
    report(release: ReleaseResource, description: string) {
        return this.client.post(this.requestContext, release.Links["ReportDefect"], { Description: description });
    }
    resolve(release: ReleaseResource) {
        return this.client.post(this.requestContext, release.Links["ResolveDefect"]);
    }
}
