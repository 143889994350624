import { createCompleteRoute } from "./CompleteRoute";
import type { CompleteRoute } from "./CompleteRoute";
import type { RouteTemplate } from "./RouteTemplate";
export type RedirectRouteDefinition<RouteParameters> = {
    isRedirect: true;
    completeRoute: CompleteRoute<RouteParameters, [
    ]>;
};
// We want to avoid redirects where possible.
// Instead of linking to redirects, can you instead link directly to the page that the redirect would otherwise take you to?
// If you are looking to add a redirect for a route that has since been changed, see `allLegacyRoutes` instead.
export function createRedirect<RouteParameters>(route: RouteTemplate<RouteParameters>): RedirectRouteDefinition<RouteParameters> {
    const completeRoute = createCompleteRoute(route, []);
    return {
        completeRoute,
        isRedirect: true,
    };
}
