import type { PropertyValues, LogLevel, PropertyValue, LoggingSink, LogLevelsWithNoError, LogLevelsWithPossibleError } from "../types";
type Log = (messageTemplate: string, propertyValues?: PropertyValues) => void;
type LogWithPossibleError = Log & ((error: unknown, messageTemplate: string, propertyValues?: PropertyValues) => void);
type UnstructuredLog = (logLevel: LogLevel, message: string, ...optionalParams: PropertyValue[]) => void;
type DevLog = (...data: unknown[]) => void;
export interface Logger {
    forContext: (propertyValues: PropertyValues) => Logger;
    verbose: Log;
    debug: Log;
    info: Log;
    warn: LogWithPossibleError;
    error: LogWithPossibleError;
    fatal: LogWithPossibleError;
    unstructuredLogEvent: UnstructuredLog;
    dev: DevLog;
}
export function createLogger(sink: LoggingSink, getContextualSouceContext: () => string, getContextualPropertyValues?: () => PropertyValues): Logger {
    function createLogLevelHandler(logLevel: LogLevelsWithNoError): Log {
        return (messageTemplate: string, propertyValues?: PropertyValues) => sink.receiveLogEvents([
            {
                logLevel,
                messageTemplate,
                propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues ?? {}),
                timestamp: new Date(),
                sourceContext: getContextualSouceContext(),
            },
        ]);
    }
    function createLogLevelHandlerWithPossibleError(logLevel: LogLevelsWithPossibleError): LogWithPossibleError {
        function logWithPossibleError(error: unknown, messageTemplate: string, propertyValues?: PropertyValues): void;
        function logWithPossibleError(messageTemplate: string, propertyValues?: PropertyValues): void;
        function logWithPossibleError(errorOrTemplate: unknown | string, messageTemplateOrPropertyValues: string | PropertyValues | undefined, propertyValues?: PropertyValues) {
            if (typeof errorOrTemplate === "string") {
                if (typeof messageTemplateOrPropertyValues === "string") {
                    throw new Error("Expected the second argument to be a set of property values, but it wasn't");
                }
                sink.receiveLogEvents([
                    {
                        logLevel,
                        messageTemplate: errorOrTemplate,
                        propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, messageTemplateOrPropertyValues ?? {}),
                        timestamp: new Date(),
                        stack: new Error().stack,
                        sourceContext: getContextualSouceContext(),
                    },
                ]);
            }
            else {
                if (typeof messageTemplateOrPropertyValues !== "string") {
                    throw new Error("Expected the second argument to be a message template, but it wasn't");
                }
                sink.receiveLogEvents([
                    {
                        logLevel,
                        messageTemplate: messageTemplateOrPropertyValues,
                        propertyValues: mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues ?? {}),
                        timestamp: new Date(),
                        error: errorOrTemplate,
                        stack: new Error().stack,
                        sourceContext: getContextualSouceContext(),
                    },
                ]);
            }
        }
        return logWithPossibleError;
    }
    return {
        verbose: createLogLevelHandler("verbose"),
        debug: createLogLevelHandler("debug"),
        info: createLogLevelHandler("information"),
        warn: createLogLevelHandlerWithPossibleError("warning"),
        error: createLogLevelHandlerWithPossibleError("error"),
        fatal: createLogLevelHandlerWithPossibleError("fatal"),
        dev: console.log,
        unstructuredLogEvent(logLevel: LogLevel, message, ...optionalParams) {
            sink.receiveLogEvents([
                {
                    logLevel,
                    message,
                    messageArguments: optionalParams,
                    timestamp: new Date(),
                    context: getContextualPropertyValues?.() ?? {},
                    sourceContext: getContextualSouceContext(),
                },
            ]);
        },
        forContext: (propertyValues: PropertyValues) => createLogger(sink, getContextualSouceContext, () => mergePropertyValues(getContextualPropertyValues?.() ?? {}, propertyValues)),
    };
}
function mergePropertyValues(...propertyValues: PropertyValues[]): PropertyValues {
    return propertyValues.reduce((p, c) => ({ ...p, ...c }), {});
}
