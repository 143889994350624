/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { DeploymentTargetResource, MachineConnectionStatus, ResourceCollection, TaskResource, GetVariablesScopedToSingleDeploymentTargetResponse } from "../resources";
import { DeploymentTargetTaskType } from "../resources/deploymentTargetTaskType";
import type { DeploymentResource, EnvironmentResource, MachineResource, NewDeploymentTargetResource } from "../resources/index";
import { BasicRepository } from "./basicRepository";
export type ListMachinesArgs = {
    skip?: number;
    take?: number;
    partialName?: string;
    name?: string;
    roles?: string;
    isDisabled?: boolean;
    healthStatuses?: string;
    commStyles?: string;
    tenantIds?: string;
    tenantTags?: string;
    environmentIds?: string;
    shellNames?: string;
    deploymentTargetTypes?: string;
};
export type UpgradeMachinesResponse = {
    Tasks: TaskResource[];
};
export class MachineRepository extends BasicRepository<DeploymentTargetResource, NewDeploymentTargetResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Machines", client, requestContext);
    }
    list(args?: ListMachinesArgs): Promise<ResourceCollection<DeploymentTargetResource>> {
        return this.client.get(this.requestContext, this.client.getLink("Machines"), args);
    }
    listByEnvironment(environment: EnvironmentResource) {
        return this.client.get(this.requestContext, environment.Links["Machines"]);
    }
    discover(host: string, port: number, type: any, proxyId: string | undefined): Promise<DeploymentTargetResource> {
        return proxyId
            ? this.client.get<DeploymentTargetResource>(this.requestContext, this.client.getLink("DiscoverMachine"), { host, port, type, proxyId })
            : this.client.get<DeploymentTargetResource>(this.requestContext, this.client.getLink("DiscoverMachine"), {
                host,
                port,
                type,
            });
    }
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus> {
        return this.client.get<MachineConnectionStatus>(this.requestContext, machine.Links["Connection"]);
    }
    getDeployments(machine: DeploymentTargetResource, options: any): Promise<ResourceCollection<TaskResource<any>>> {
        return this.client.get<ResourceCollection<TaskResource<any>>>(this.requestContext, machine.Links["TasksTemplate"], { ...options, type: DeploymentTargetTaskType.Deployment });
    }
    getRunbookRuns(machine: DeploymentTargetResource, options: any): Promise<ResourceCollection<TaskResource<any>>> {
        return this.client.get<ResourceCollection<TaskResource<any>>>(this.requestContext, machine.Links["TasksTemplate"], { ...options, type: DeploymentTargetTaskType.RunbookRun });
    }
    hosted() {
        const allArgs = { id: "hosted" };
        return this.client.get(this.requestContext, this.client.getLink("Machines"), allArgs);
    }
    listByDeployment(deployment: DeploymentResource) {
        return this.client.get(this.requestContext, this.client.getLink("Machines"), { deploymentId: deployment.Id, id: "all" });
    }
    // TODO update all the upgrade methods to use proper links once they are available.
    upgradeAllTentacles(): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/machines/upgrade", {
            SpaceId: this.client.spaceId,
        });
    }
    upgradeTentacle(machineId: string): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/machines/upgrade", {
            SpaceId: this.client.spaceId,
            MachineIds: [machineId],
            UpgradeLevel: "UpgradeAvailable",
        });
    }
    upgradeAllWorkers(): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/workers/upgrade", {
            SpaceId: this.client.spaceId,
        });
    }
    upgradeAllTargets(): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/deploymenttargets/upgrade", {
            SpaceId: this.client.spaceId,
        });
    }
    upgradeAllWorkersInWorkerPool(workerPoolId: string): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/workers/upgrade", {
            SpaceId: this.client.spaceId,
            WorkerPoolId: workerPoolId,
        });
    }
    upgradeAllTargetsInEnvironment(environmentId: string): Promise<UpgradeMachinesResponse> {
        return this.client.post(this.requestContext, "/api/deploymenttargets/upgrade", {
            SpaceId: this.client.spaceId,
            TargetEnvironmentId: environmentId,
        });
    }
    variablesScopedOnlyToThisDeploymentTarget(machine: DeploymentTargetResource): Promise<GetVariablesScopedToSingleDeploymentTargetResponse> {
        return this.client.get<GetVariablesScopedToSingleDeploymentTargetResponse>(this.requestContext, machine.Links["SinglyScopedVariableDetails"]);
    }
}
