import { useCallback } from "react";
import type { AnalyticsEvent } from "./AnalyticsEvent";
import { useAnalyticSession } from "./AnalyticsSessionProvider";
export function useTrackEvent(): TrackEvent {
    const session = useAnalyticSession();
    return useCallback<TrackEvent>(<T extends AnalyticsEvent<string>>(event: T) => {
        const { eventName, ...other } = event;
        session.track(eventName, other);
    }, [session]);
}
export type TrackEvent = <T extends AnalyticsEvent<string>>(event: T) => void;
