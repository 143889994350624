// eslint-disable-next-line @typescript-eslint/no-explicit-any
// import type { RouteSegment } from "./RouteSegment";
import type { PageRouteDefinition } from "./PageRouteDefinition";
import type { RouteSegment } from "./RouteSegment";
import type { RedirectRouteDefinition } from "./createRedirect";
export function createLinks<RouteParams, ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint, Redirects extends RedirectsConstraint>(routes: RouteSegment<RouteParams, ChildRoutes, Pages, Redirects>): FlattenRouteSegment<RouteSegment<RouteParams, ChildRoutes, Pages, Redirects>> {
    const pages = routes.pages;
    const redirects = Object.entries(routes.redirects).reduce((p, [key, redirectRouteDefinition]) => ({
        ...p,
        [key]: redirectRouteDefinition.completeRoute,
    }), {});
    const childRoutes = routes.childRouteSegments;
    const childRouteLinks = Object.entries(childRoutes).reduce((p, [key, childRouteSegment]) => {
        // Suppressing this because the error just tells us that typescript will use `any`. That's what it would be using here anyway because we don't have more specific types
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2589: Type instantiation is excessively deep and possibly infinite.
        const linksForChildRouteSegment = createLinks(childRouteSegment);
        // Assert that the keys are unique
        const linkKeysForChildRouteSegment = Object.keys(linksForChildRouteSegment);
        const existingKnownLinkKeys = Object.keys(pages).concat(Object.keys(redirects)).concat(Object.keys(p));
        linkKeysForChildRouteSegment.forEach((s) => {
            if (existingKnownLinkKeys.includes(s)) {
                throw new Error(`Duplicate route key ${s}`);
            }
        });
        return {
            ...p,
            ...linksForChildRouteSegment,
        };
    }, {});
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {
        ...pages,
        ...redirects,
        ...childRouteLinks,
    } as PageLinks<ChildRoutes, Pages, Redirects>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageConstraint = PageRouteDefinition<any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteSegmentConstraint = RouteSegment<any, any, any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RedirectConstraint = RedirectRouteDefinition<any>;
type RouteSegmentsConstraint = Record<string, RouteSegmentConstraint>;
type PagesConstraint = Record<string, PageConstraint>;
type RedirectsConstraint = Record<string, RedirectConstraint>;
type PageLinks<ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint, Redirects extends RedirectsConstraint> = {
    [K in keyof Pages]: Pages[K];
} & {
    [K in keyof Redirects]: Redirects[K]["completeRoute"];
} & ExtractPagesAndRedirectsFromRouteSegment<ChildRoutes>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FlattenRouteSegment<T extends RouteSegmentConstraint> = T extends RouteSegment<any, infer ChildRouteSegments extends RouteSegmentsConstraint, infer Pages, infer Redirects> ? PageLinks<ChildRouteSegments, Pages, Redirects> : never;
// ObjectValues gives us a union type across all values of ChildRoutes. FlattenRouteSegment distributes across this union type, recursively extracting all keys.
// Finally, we convert the union type to an intersection type.
type ExtractPagesAndRedirectsFromRouteSegment<ChildRoutes extends Record<string, RouteSegmentConstraint>> = UnionToIntersection<FlattenRouteSegment<ObjectValues<ChildRoutes>>>;
// Equivalent of (typeof Object.values(T))[number]. E.g. { a: { b: 1 }; c: { d: 2 } } => { b: 1 } | { d: 2 }
type ObjectValues<ChildRoutes extends Record<string, RouteSegmentConstraint>> = ChildRoutes[keyof ChildRoutes];
// Note that this uses { a: (x: T) => unknown } instead of (x: T) => unknown, which is more typical for a UnionToIntersection mapped type
// This is because Webstorm seems to interpret the resulting type as callable, even though we always map it away from a callable function.
// If we instead wrap it in an object, the result is never callable. We still need a function on the object because UnionToIntersection works
// by having T in a contravariant position.
// { a: 1 } | { b: 2 } => { a: 1 } & { b: 2 }
type UnionToIntersection<T> = (T extends unknown ? {
    a: (x: T) => unknown;
} : never) extends {
    a: (x: infer R) => unknown;
} ? R : never;
