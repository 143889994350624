/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { MachineConnectionStatus, MachineResource, NewWorkerMachineResource, ResourceCollection, WorkerMachineResource } from "../resources";
import { BasicRepository } from "./basicRepository";
export type ListWorkerArgs = {
    skip?: number;
    take?: number;
    partialName?: string;
    name?: string;
    roles?: string;
    isDisabled?: boolean;
    healthStatuses?: string;
    commStyles?: string;
    tenantIds?: string;
    tenantTags?: string;
    environmentIds?: string;
    shellNames?: string;
    workerPoolIds?: string;
};
export class WorkerRepository extends BasicRepository<WorkerMachineResource, NewWorkerMachineResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Workers", client, requestContext);
    }
    list(args?: ListWorkerArgs): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get(this.requestContext, this.client.getLink("Workers"), args);
    }
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus> {
        return this.client.get<MachineConnectionStatus>(this.requestContext, machine.Links["Connection"]);
    }
    discover(host: string, port: number, type: any, proxyId: string | undefined): Promise<WorkerMachineResource> {
        return proxyId
            ? this.client.get<WorkerMachineResource>(this.requestContext, this.client.getLink("DiscoverWorker"), { host, port, type, proxyId })
            : this.client.get<WorkerMachineResource>(this.requestContext, this.client.getLink("DiscoverWorker"), {
                host,
                port,
                type,
            });
    }
}
