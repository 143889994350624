import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { MixedSpaceResource } from "./mixedSpaceResource";
import type { NamedReferenceItem } from "./namedReferenceItem";
import type { NamedResource } from "./namedResource";
import type { IIdName } from "./resource";
export const TeamConstants = {
    SpaceManagersTeamIdPrefix: "teams-spacemanagers-",
};
export interface TeamResource extends NamedResource, MixedSpaceResource, ResourceWithSlug {
    ExternalSecurityGroups: NamedReferenceItem[];
    MemberUserIds: string[];
    CanBeDeleted: boolean;
    CanBeRenamed: boolean;
    CanChangeMembers: boolean;
    Description: string;
    // can change exists at team level to support built in teams
    CanChangeRoles: boolean;
}
export interface TeamNameResource extends NamedResource {
    Name: string;
}
export interface GetTeamsLookupBffResponse {
    Teams: GetTeamsLookupBffResponseTeam[];
}
export interface GetTeamsLookupBffResponseTeam extends IIdName {
    SpaceId: string;
}
