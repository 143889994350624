/* eslint-disable @typescript-eslint/no-explicit-any */
import { OctoLink } from "@octopusdeploy/utilities";
import type { Client, RequestContext } from "../client";
import { Repository } from "../repository";
import type { ApiKeyCreatedResource, ApiKeyResource, LoginCommand, ResourceCollection, SpaceResource, UserResource } from "../resources";
import type { AccessTokenResource } from "../resources/accessTokenResource";
import type { CreateFavouriteProjectForUserBffCommand } from "../resources/userFavouriteProjects/createFavouriteProjectForUserBffCommand";
import type { CreateFavouriteProjectForUserBffResponse } from "../resources/userFavouriteProjects/createFavouriteProjectForUserBffResponse";
import type { DeleteFavouriteProjectForUserBffResponse } from "../resources/userFavouriteProjects/deleteFavouriteProjectForUserBffResponse";
import type { GetFavouriteProjectsForUserBffResponse } from "../resources/userFavouriteProjects/getFavouriteProjectsForUserBffResponse";
import { BasicRepository } from "./basicRepository";
export class UserRepository extends BasicRepository<UserResource, UserResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Users", client, requestContext);
    }
    signIn(loginCommand: LoginCommand): Promise<UserResource> {
        return this.client.post<UserResource>(this.requestContext, this.client.getLink("SignIn"), loginCommand).then((authenticatedUser) => {
            const antiforgeryToken = this.client.getAntiforgeryToken();
            if (antiforgeryToken) {
                return authenticatedUser;
            }
            else {
                throw new Error(`The required anti-forgery cookie is missing. Perhaps your browser or another network device is blocking cookies? See ${OctoLink.Create("CSRE")} for more details and troubleshooting.`);
            }
        });
    }
    signOut() {
        return this.client.post(this.requestContext, this.client.getLink("SignOut"), {});
    }
    getCurrent() {
        return this.client.get<UserResource>(this.requestContext, this.client.getLink("CurrentUser"));
    }
    getSpaces(user: UserResource) {
        return this.client.get<SpaceResource[]>(this.requestContext, user.Links["Spaces"]);
    }
    createApiKey(user: UserResource, purpose: any, expires: Date | null): Promise<ApiKeyCreatedResource> {
        return this.client.post(this.requestContext, user.Links["ApiKeys"], { Purpose: purpose, Expires: expires });
    }
    listApiKeys(user: UserResource): Promise<ResourceCollection<ApiKeyResource>> {
        return this.client.get(this.requestContext, user.Links["ApiKeys"], { take: Repository.takeAll });
    }
    revokeApiKey(apiKey: ApiKeyResource) {
        return this.client.del(this.requestContext, apiKey.Links["Self"]);
    }
    register(registerCommand: any) {
        return this.client.post(this.requestContext, this.client.getLink("Register"), registerCommand);
    }
    getTriggers(user: UserResource) {
        return this.client.get(this.requestContext, user.Links["Triggers"]);
    }
    revokeSessions(user: UserResource) {
        return this.client.put(this.requestContext, user.Links["RevokeSessions"]);
    }
    generateAccessToken(): Promise<AccessTokenResource> {
        return this.client.post(this.requestContext, this.client.getLink("AccessToken"));
    }
    // UserFavouriteProjects
    async createProjectFavourite(projectId: string): Promise<CreateFavouriteProjectForUserBffResponse> {
        const createFavouriteProjectCommand: CreateFavouriteProjectForUserBffCommand = {
            ProjectId: projectId,
        };
        const response = await this.client.post<CreateFavouriteProjectForUserBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/userFavouriteProjects/create", createFavouriteProjectCommand);
        this.client.dispatchEvent({ type: "UserFavouriteProjectCreated" });
        return response;
    }
    async deleteProjectFavourite(projectId: string): Promise<DeleteFavouriteProjectForUserBffResponse> {
        await this.client.delete(this.requestContext, "~/bff/spaces/{spaceId}/userFavouriteProjects/delete/{projectId}", undefined, { projectId });
        this.client.dispatchEvent({ type: "UserFavouriteProjectDeleted" });
        return {};
    }
    async getProjectFavourites(): Promise<GetFavouriteProjectsForUserBffResponse> {
        const response = await this.client.get<GetFavouriteProjectsForUserBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/userFavouriteProjects/summaries");
        return response;
    }
}
