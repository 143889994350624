import type { Client, RequestContext } from "../client";
import type { ArtifactResource } from "../resources/artifactResource";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type ArtifactListArgs = {
    regarding?: string;
    order?: string;
} & ListArgs;
export class ArtifactRepository extends BasicRepository<ArtifactResource, ArtifactResource, ArtifactListArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Artifacts", client, requestContext);
    }
}
