export const SettingsIdValues = {
    ActiveDirectory: "authentication-directoryservices",
    ArchiveLimits: "archive-limit",
    Authentication: "authentication",
    AzureAD: "authentication-aad",
    AzureDevOpsIssueTracker: "issuetracker-azuredevops-v2",
    EventRetention: "eventretention",
    GithubIssueTracker: "issuetracker-github",
    GoogleApps: "authentication-googleapps",
    GuestLogin: "authentication-guest",
    JiraIntegration: "jira-integration",
    JiraServiceManagementIntegration: "jiraservicemanagement-integration",
    LDAP: "authentication-ldap",
    OctopusID: "authentication-octopusid",
    Okta: "authentication-od",
    RetentionPolicies: "retentionpolicies",
    ServerFolders: "server-folders",
    ServiceNowIntegration: "servicenow-integration",
    Updates: "upgrades",
    UsernamePassword: "authentication-usernamepassword",
    WebPortal: "webportal",
    WebRequestProxy: "server-proxy",
} as const;
export type SettingsId = (typeof SettingsIdValues)[keyof typeof SettingsIdValues];
