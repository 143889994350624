import type { Client, RequestContext } from "../client";
import type { RouteArgs } from "../resolver";
import type { ChannelResource, CreateChannelCommand, GitReferenceSatisfiesChannelGitReferenceRulesResponse, GitReferenceSatisfiesChannelGitResourceRulesResponse, ModifyChannelCommand, ProjectResource, ReleaseResource, ResourceCollection, VersionRuleTestResponse, } from "../resources";
import type { ICommitCommand } from "../resources/commitCommand";
import type { FeedType } from "../resources/feedResource";
import type { ListArgs } from "./basicRepository";
import type { ProjectRepository } from "./projectRepository";
import { ProjectScopedRepository } from "./projectScopedRepository";
export type ReleasesListArgs = {
    searchByVersion?: string;
} & ListArgs;
export type SearchOptions = {
    version: string;
    versionRange: string;
    preReleaseTag: string;
    feedType: FeedType;
};
export class ChannelRepository extends ProjectScopedRepository<ChannelResource, ChannelResource> {
    constructor(projectRepository: ProjectRepository, client: Client, requestContext: RequestContext) {
        super(projectRepository, "Channels", client, requestContext);
    }
    ruleTest(searchOptions: SearchOptions) {
        return this.client.post<VersionRuleTestResponse>(this.requestContext, this.client.getLink("VersionRuleTest"), searchOptions);
    }
    gitReferenceRuleValidation(projectId: string, channelId: string, gitReference: string | undefined): Promise<GitReferenceSatisfiesChannelGitReferenceRulesResponse> {
        return this.client.get(this.requestContext, `~/api/spaces/{spaceId}/projects/${projectId}/channels/${channelId}/git-reference-rule-validation/v1?gitReference=${gitReference}`);
    }
    gitResourceRuleValidation(projectId: string, channelId: string, gitReference: string | undefined, actionSlug: string | undefined, gitDependencyName: string): Promise<GitReferenceSatisfiesChannelGitResourceRulesResponse> {
        return this.client.get(this.requestContext, `~/api/spaces/{spaceId}/projects/${projectId}/channels/${channelId}/git-resource-rule-validation/v1?gitReference=${gitReference}&deploymentActionSlug=${actionSlug}&gitDependencyName=${gitDependencyName}`);
    }
    getReleases(channel: ChannelResource, options?: ReleasesListArgs): Promise<ResourceCollection<ReleaseResource>> {
        return this.client.get(this.requestContext, channel.Links["Releases"], options);
    }
    modify(channel: ChannelResource, args?: {} | undefined): Promise<ChannelResource> {
        const payload: ModifyChannelCommand = channel;
        this.addCommitMessage(payload, args);
        if (payload !== undefined) {
            return this.client.update(this.requestContext, channel.Links.Self, payload);
        }
        else {
            return super.modify(channel, args);
        }
    }
    createForProject(projectResource: ProjectResource, channel: ChannelResource, args: RouteArgs): Promise<ChannelResource> {
        const payload: CreateChannelCommand = channel;
        this.addCommitMessage(payload, args);
        if (payload !== undefined) {
            const link = projectResource.Links[this.collectionLinkName];
            return this.client.create<ChannelResource, ChannelResource>(this.requestContext, link, payload, args);
        }
        else {
            return super.createForProject(projectResource, channel, args);
        }
    }
    addCommitMessage(command: ICommitCommand, args?: {} | undefined) {
        if (args !== undefined && "gitRef" in args && "commitMessage" in args && (typeof args.commitMessage === "string" || args.commitMessage === undefined)) {
            command.ChangeDescription = args["commitMessage"];
        }
    }
}
