import type { Client, RequestContext } from "../client";
import type { ExternalSecurityGroupProviderResource } from "../resources/externalSecurityGroupProviderResource";
export class ExternalSecurityGroupProviderRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    all(): Promise<ExternalSecurityGroupProviderResource[]> {
        return this.client.get(this.requestContext, this.client.getLink("ExternalSecurityGroupProviders"));
    }
}
