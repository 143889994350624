import type { NonEmptyArray } from "@octopusdeploy/type-utils";
import { isArrayLengthAtLeast } from "@octopusdeploy/type-utils";
import { optionalBooleanQueryParam, createArrayQueryParam } from "./query/QueryStringParam";
type SpaceId = string;
export type SpacesQueryParam = NonEmptyArray<SpaceId> | "all" | "unspecified";
export interface SpacePartitionQueryParams {
    spaces: SpacesQueryParam;
    includeSystem: boolean | undefined;
}
export const spacesQueryParam = createArrayQueryParam<"spaces", SpacesQueryParam>("spaces", (serializedValues) => {
    if (isArrayLengthAtLeast(serializedValues, 1)) {
        if (serializedValues.some((v) => v === "all"))
            return "all";
        return serializedValues;
    }
    return "unspecified";
}, (value) => {
    if (value === "all")
        return ["all"];
    if (value === "unspecified" || value === undefined)
        return [];
    return value;
});
export const includeSystemParam = optionalBooleanQueryParam("includeSystem");
export const spacePartitionQueryParams = [spacesQueryParam, includeSystemParam] as const;
