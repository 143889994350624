/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Client } from "../client";
export interface ApiVersionCallbacks {
    onMatchingVersionsDetected: () => void;
    onMismatchedVersionsDetected: (apiVersion: string, clientVersion: string | undefined) => void;
}
const HTTPHeader = "Octopus-Node";
export const serverVersionNotificationCheck = (request: XMLHttpRequest, client: Client, apiVersionCallbacks: ApiVersionCallbacks) => {
    const serverInfo = client.tryGetServerInformation();
    const clientVersion = serverInfo ? serverInfo.version : undefined;
    const apiVersion = getApiVersion(request);
    if (!apiVersion) {
        return;
    }
    //TODO: Check if there is really a need to have this on every ajax call? Can this be done at startup once?
    if (apiVersion === clientVersion) {
        apiVersionCallbacks.onMatchingVersionsDetected();
    }
    else {
        apiVersionCallbacks.onMismatchedVersionsDetected(apiVersion, clientVersion);
    }
};
function getApiVersion(request: XMLHttpRequest): string | undefined {
    let nodeVersion = request.getResponseHeader(HTTPHeader);
    if (!nodeVersion) {
        return;
    }
    nodeVersion = nodeVersion.split("; ").find((v) => v.startsWith("version="))!;
    if (!nodeVersion) {
        return;
    }
    return nodeVersion.substr(8);
}
