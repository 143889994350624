/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import type { DeploymentProcessResource } from "./deploymentProcessResource";
import { TriggerFilterResource, TriggerActionType, TriggerActionResource, TriggerFilterType } from "./triggerResource";
export class TriggerFeedFilterResource extends TriggerFilterResource {
    constructor(packages: DeploymentActionSlugPackageResource[]) {
        super();
        this.FilterType = TriggerFilterType.FeedFilter;
        this.Packages = packages;
    }
    Packages: DeploymentActionSlugPackageResource[] = [];
}
export interface DeploymentActionSlugPackageResource {
    DeploymentActionSlug: string;
    PackageReference?: string;
}
export class CreateReleaseActionResource extends TriggerActionResource {
    ChannelId: string = undefined!;
    ActionType: TriggerActionType = TriggerActionType.CreateRelease;
}
export const toDeploymentActionPackage = (pkg: DeploymentActionSlugPackageResource, deploymentProcess: DeploymentProcessResource): DeploymentActionPackageResource => {
    return { DeploymentAction: getIdOrNameFromSlug(pkg.DeploymentActionSlug, deploymentProcess), PackageReference: pkg.PackageReference };
};
export const getIdOrNameFromSlug = (slug: string, deploymentProcess: DeploymentProcessResource): string => {
    return deploymentProcess?.Steps.flatMap((step) => step.Actions).find((action) => action.Slug === slug)?.Name || "not-found";
};
export const toDeploymentActionSlugPackage = (pkg: DeploymentActionPackageResource, deploymentProcess: DeploymentProcessResource): DeploymentActionSlugPackageResource => {
    return { DeploymentActionSlug: getSlugFromIdOrName(pkg.DeploymentAction, deploymentProcess), PackageReference: pkg.PackageReference };
};
export const getSlugFromIdOrName = (name: string, deploymentProcess: DeploymentProcessResource): string => {
    return deploymentProcess?.Steps.flatMap((step) => step.Actions).find((action) => action.Name === name)?.Slug || "not-found";
};
