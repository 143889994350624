/* eslint-disable no-console */
type ConsoleLogger = typeof console.log;
export interface ConsoleLoggerController {
    originalConsoleLogger: ConsoleLogger;
    setConsoleLogger: (newConsoleLogger: ConsoleLogger) => void;
}
function createConsoleLoggerController(originalConsoleLogger: ConsoleLogger, setConsoleLogger: (newConsoleLogger: ConsoleLogger) => void): ConsoleLoggerController {
    return {
        originalConsoleLogger,
        setConsoleLogger,
    };
}
const logConsole = createConsoleLoggerController(console.log, (newConsoleLogger) => (console.log = newConsoleLogger));
const debugConsole = createConsoleLoggerController(console.debug, (newConsoleLogger) => (console.debug = newConsoleLogger));
const infoConsole = createConsoleLoggerController(console.info, (newConsoleLogger) => (console.info = newConsoleLogger));
const warnConsole = createConsoleLoggerController(console.warn, (newConsoleLogger) => (console.warn = newConsoleLogger));
const errorConsole = createConsoleLoggerController(console.error, (newConsoleLogger) => (console.error = newConsoleLogger));
export const consoleLoggers = {
    logConsole,
    debugConsole,
    infoConsole,
    warnConsole,
    errorConsole,
};
export type ConsoleLoggers = typeof consoleLoggers;
