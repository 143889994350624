import type { UserPermissions, UserResource } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import React from "react";
import { OctopusSessionPermissionsProvider } from "./useOctopusSessionPermissions";
import { OctopusSessionUserProvider } from "./useOctopusSessionUser";
export interface OctopusSessionProviderProps {
    currentUser: UserResource | null;
    currentPermissions: UserPermissions | null;
}
export function OctopusSessionProvider({ currentUser, currentPermissions, children }: PropsWithChildren<OctopusSessionProviderProps>) {
    return (<OctopusSessionUserProvider currentUser={currentUser}>
            <OctopusSessionPermissionsProvider currentPermissions={currentPermissions}>{children}</OctopusSessionPermissionsProvider>
        </OctopusSessionUserProvider>);
}
