/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
export class ExternalUsersRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    search(partialName: string) {
        return this.client.get(this.requestContext, this.client.getLink("ExternalUserSearch"), { partialName });
    }
    searchProvider(providerUrl: any, partialName: string): Promise<any> {
        return this.client.get(this.requestContext, providerUrl, { partialName });
    }
}
