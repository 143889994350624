import type { Client, RequestContext } from "../client";
import type { GitCredentialResource, GitCredentialUsage } from "../resources";
import { BasicRepository } from "./basicRepository";
export class GitCredentialRepository extends BasicRepository<GitCredentialResource, GitCredentialResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("GitCredentials", client, requestContext);
    }
    usage(gitCredential: GitCredentialResource) {
        return this.client.get<GitCredentialUsage>(this.requestContext, gitCredential.Links.Usage);
    }
}
