import type { Client, GlobalAndSpaceRootLinks, RequestContext } from "../client";
import type { EnabledFeatureToggles } from "../resources/enabledFeatureToggles";
import type { ResourceWithId } from "../resources/resource";
export class ConfigurationRepository<TResource extends ResourceWithId> {
    protected client: Client;
    private configurationLinkName: GlobalAndSpaceRootLinks;
    constructor(configurationLinkName: GlobalAndSpaceRootLinks, client: Client, protected readonly requestContext: RequestContext) {
        this.configurationLinkName = configurationLinkName;
        this.client = client;
    }
    get(): Promise<TResource> {
        return this.client.get<TResource>(this.requestContext, this.client.getLink(this.configurationLinkName));
    }
    modify(resource: TResource): Promise<TResource> {
        return this.client.update<TResource>(this.requestContext, resource.Links["Self"], resource);
    }
    clearCache(): Promise<void> {
        const clearCacheLink = this.client.getLink("VersionControlClearCache");
        return this.client.post(this.requestContext, clearCacheLink, {});
    }
    enabledFeatureToggles(): Promise<EnabledFeatureToggles> {
        const enabledFeatureToggleLink = this.client.getLink("EnabledFeatureToggles");
        return this.client.get(this.requestContext, enabledFeatureToggleLink, {});
    }
    featureToggleClientIdentifier(): Promise<string> {
        return this.client.get(this.requestContext, "~/api/projects/featuretoggles/clientidentifier", {});
    }
}
