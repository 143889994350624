import type { Client, RequestContext } from "../client";
export class MachineShellsRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    all(): Promise<string[]> {
        return this.client.get(this.requestContext, this.client.getLink("MachineShells"));
    }
}
