import type { Client, RequestContext } from "../client";
import type { RouteArgs } from "../resolver";
import type { DashboardItemResource, ResourceCollection, RunbooksDashboardItemResource } from "../resources";
import type { NewDynamicEnvironmentResource, DynamicEnvironmentResource } from "../resources/dynamicEnvironmentResource";
export type GetManyDynamicEnvironmentsResponseV1 = {
    Environments: ResourceCollection<DynamicEnvironmentResource>;
};
export type GetDynamicEnvironmentByIdResponseV1 = {
    Environment: DynamicEnvironmentResource;
};
export type CreateDynamicEnvironmentResponseV1 = {
    Id: string;
};
export enum GetDynamicEnvironmentsOverviewRequestV1SortField {
    EnvironmentName,
    LatestActivity,
    ActiveProjectCount
}
export enum GetDynamicEnvironmentsOverviewRequestV1SortOrder {
    Ascending,
    Descending
}
export type GetDynamicEnvironmentsOverviewActiveProjectFilter = "WithActiveProjects" | "WithoutActiveProjects";
export type GetDynamicEnvironmentsOverviewRequestV1 = {
    spaceId: string;
    skip: number;
    take: number;
    sortField: GetDynamicEnvironmentsOverviewRequestV1SortField;
    sortOrder: GetDynamicEnvironmentsOverviewRequestV1SortOrder;
    name?: string;
    state?: Array<DynamicEnvironmentOverviewState>;
    activeProjects?: GetDynamicEnvironmentsOverviewActiveProjectFilter;
};
export type GetDynamicEnvironmentsOverviewResponseV1 = {
    DynamicEnvironmentOverviews: DynamicEnvironmentOverview[];
    TotalCount: number;
};
export type DynamicEnvironmentOverviewState = "Active" | "Deprovisioning" | "Deprovisioned" | "DeprovisioningFailed";
export type DynamicEnvironmentProjectActivityType = "Deployment" | "Provisioning" | "Deprovisioning";
export type DynamicEnvironmentOverview = {
    EnvironmentId: string;
    EnvironmentName: string;
    EnvironmentState: DynamicEnvironmentOverviewState;
    LatestActivityTime?: string;
    ActiveProjectCount: number;
};
export enum GetDynamicEnvironmentOverviewRequestV1SortField {
    ProjectName,
    LatestActivity,
    DeprovisioningOrder
}
export enum GetDynamicEnvironmentOverviewRequestV1SortOrder {
    Ascending,
    Descending
}
export type GetDynamicEnvironmentOverviewRequestV1 = {
    environmentId: string;
    skip: number;
    take: number;
    sortField: GetDynamicEnvironmentOverviewRequestV1SortField;
    sortOrder: GetDynamicEnvironmentOverviewRequestV1SortOrder;
    projectName?: string;
    projectState?: Array<DynamicEnvironmentOverviewProjectState>;
};
export type GetDynamicEnvironmentOverviewResponseV1 = {
    EnvironmentId: string;
    EnvironmentName: string;
    EnvironmentState: DynamicEnvironmentOverviewState;
    ActiveProjectCount: number;
    AssociatedProjects: Array<DynamicEnvironmentOverviewProject>;
    AssociatedProjectsTotalCount: number;
};
export type DynamicEnvironmentOverviewProjectState = "Provisioning" | "ProvisioningFailed" | "Provisioned" | "Deprovisioning" | "DeprovisioningFailed" | "Deprovisioned";
export type Activity = {
    ActivityType: DynamicEnvironmentProjectActivityType;
    Deployment?: DashboardItemResource;
};
export type DynamicEnvironmentOverviewProject = {
    ProjectId: string;
    ProjectName: string;
    ProjectLogoUrl?: string;
    ProjectState: DynamicEnvironmentOverviewProjectState;
    Deployment?: DashboardItemResource;
    Provisioning?: RunbooksDashboardItemResource;
    Deprovisioning?: RunbooksDashboardItemResource;
    DeprovisionedDateTime?: string;
};
export class DynamicEnvironmentRepository {
    protected client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    async create(resource: NewDynamicEnvironmentResource, args?: RouteArgs): Promise<DynamicEnvironmentResource> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id = (await this.client.post<CreateDynamicEnvironmentResponseV1>(this.requestContext, this.client.getLink("DynamicEnvironmentsCreateV1"), resource, args!)).Id;
        return (await this.client.get<GetDynamicEnvironmentByIdResponseV1>(this.requestContext, this.client.getLink("DynamicEnvironments"), { id })).Environment;
    }
    async getMany(args?: RouteArgs): Promise<ResourceCollection<DynamicEnvironmentResource>> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return (await this.client.get<GetManyDynamicEnvironmentsResponseV1>(this.requestContext, this.client.getLink("DynamicEnvironments"), args!)).Environments;
    }
    async get(id: string, args?: RouteArgs): Promise<DynamicEnvironmentResource> {
        return (await this.client.get<GetDynamicEnvironmentByIdResponseV1>(this.requestContext, this.client.getLink("DynamicEnvironments"), { id })).Environment;
    }
    async del(id: string, args?: RouteArgs): Promise<unknown> {
        const path = this.client.getLink("DynamicEnvironments");
        return await this.client.del(this.requestContext, path, null, { id });
    }
    getDynamicEnvironmentsOverview(request: GetDynamicEnvironmentsOverviewRequestV1): Promise<GetDynamicEnvironmentsOverviewResponseV1> {
        return this.client.get<GetDynamicEnvironmentsOverviewResponseV1>(this.requestContext, "~/bff/spaces/{spaceId}/environments/dynamic/overview{?skip,take,sortField,sortOrder,name,state,activeProjects}", request);
    }
    getDynamicEnvironmentOverview(request: GetDynamicEnvironmentOverviewRequestV1): Promise<GetDynamicEnvironmentOverviewResponseV1> {
        return this.client.get<GetDynamicEnvironmentOverviewResponseV1>(this.requestContext, "~/bff/spaces/{spaceId}/environments/dynamic/{environmentId}/overview{?skip,take,sortField,sortOrder,projectName,projectState}", request);
    }
    async deprovision(id: string, args?: RouteArgs): Promise<unknown> {
        const path = this.client.getLink("DynamicEnvironmentsDeprovisionV1");
        return await this.client.post(this.requestContext, path, null, { id });
    }
    async deprovisionProject(id: string, projectId: string, args?: RouteArgs): Promise<unknown> {
        const path = this.client.getLink("DynamicEnvironmentsDeprovisionProjectV1");
        return await this.client.post(this.requestContext, path, { ProjectId: projectId }, { environmentId: id });
    }
}
