import type { Client, RequestContext } from "../client";
import type { SmtpConfigurationResource, SmtpIsConfiguredResource } from "../resources";
import { ConfigurationRepository } from "./configurationRepository";
export class SmtpConfigurationRepository extends ConfigurationRepository<SmtpConfigurationResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("SmtpConfiguration", client, requestContext);
    }
    IsConfigured() {
        return this.client.get<SmtpIsConfiguredResource>(this.requestContext, this.client.getLink("SmtpIsConfigured"));
    }
}
