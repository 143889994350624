export function generateSlug(originalString: string, trimTrailingDashes: boolean = true) {
    const value = trimTrailingDashes ? originalString.trim() : originalString;
    let slug = value
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, "-") // remove invalid characters
        .replace(/\s+/g, "-") // collapse whitespace and replace with dash
        .replace(/-+/g, "-") // collapse multiple dashes
        .replace(/^-/g, ""); // trim leading dashes
    if (trimTrailingDashes) {
        slug = slug.replace(/-$/g, "");
    }
    return slug;
}
