import type { Client, RequestContext } from "../client";
import type { ActionTemplateResource } from "../resources/actionTemplateResource";
import type { CommunityActionTemplateResource } from "../resources/communityActionTemplateResource";
export class CommunityActionTemplateRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    get(id: string) {
        const allArgs = { ...{}, ...{ id } };
        return this.client.get<CommunityActionTemplateResource>(this.requestContext, this.client.getLink("CommunityActionTemplates"), allArgs);
    }
    install(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.post<ActionTemplateResource>(this.requestContext, communityActionTemplate.Links["Installation"]);
    }
    updateInstallation(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.put<ActionTemplateResource>(this.requestContext, communityActionTemplate.Links["Installation"]);
    }
}
