import type { CompleteRoute } from "./CompleteRoute";
import { createCompleteRoute } from "./CompleteRoute";
import type { RouteTemplate } from "./RouteTemplate";
import type { UnknownQueryParam } from "./query/QueryStringParam";
export type PageRouteDefinition<RouteParameters, QueryParameters extends UnknownQueryParam[]> = CompleteRoute<RouteParameters, QueryParameters>;
export function createPageRoute<RouteParameters, QueryParams extends UnknownQueryParam[]>(template: RouteTemplate<RouteParameters>, queryParamDefinitions: [
    ...QueryParams
]): PageRouteDefinition<RouteParameters, QueryParams> {
    return createCompleteRoute(template, queryParamDefinitions);
}
