import { createDynamicSink, createCompositeSink, createConsoleSink } from "../sinks";
import type { PropertyValues, WellKnownPropertyValues, LoggingSink } from "../types";
import { createLogger } from "./createLogger";
import { consoleLoggers } from "./originalConsoleLoggers";
const dynamicSink = createDynamicSink();
const rootSink = createCompositeSink(createConsoleSink(consoleLoggers), dynamicSink);
export const logger = createLogger(rootSink, () => loggerContext.Application ?? "Unknown", () => attachedGlobalContexts.reduce((prev, current) => ({ ...prev, ...current.getProperties() }), { ...loggerContext, SourceContext: loggerContext.Application ?? "Unknown" }));
type GlobalLoggingContext = {
    Application?: string;
};
let loggerContext: GlobalLoggingContext = {};
let attachedGlobalContexts: GlobalLoggingContextProvider<PropertyValues>[] = [];
export type GlobalLoggingContextProvider<T extends PropertyValues> = {
    getProperties: () => T;
    reset?: () => void;
};
function attachContextProvider<T extends WellKnownPropertyValues<{}>>(provider: GlobalLoggingContextProvider<T>): () => void {
    attachedGlobalContexts.push(provider);
    return () => (attachedGlobalContexts = attachedGlobalContexts.filter((p) => p !== provider));
}
export const globalLogConfiguration = {
    attachSink: (sink: LoggingSink) => dynamicSink.attachSink(sink),
    attachContextProvider,
    flush: () => rootSink.flush?.(),
};
export const globalLogContext = {
    resetContext() {
        loggerContext = {};
        attachedGlobalContexts.forEach((context) => context.reset?.());
    },
    setApplication(applicationName: string) {
        loggerContext.Application = applicationName;
    },
};
