import { css } from "@emotion/css";
import { Popover } from "@octopusdeploy/design-system-components";
import type { DropdownButtonState } from "@octopusdeploy/design-system-components";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { PropsWithChildren } from "react";
interface ButtonProps {
    className?: string;
    dropdownButtonState: DropdownButtonState;
    accessibleName: string;
    disabled?: boolean;
}
const Button = ({ className, dropdownButtonState, accessibleName, disabled, children }: PropsWithChildren<ButtonProps>) => {
    return (<button className={className} ref={dropdownButtonState.setButtonRef} onClick={disabled ? undefined : dropdownButtonState.toggleDropdown} {...dropdownButtonState.buttonAriaAttributes} aria-label={accessibleName}>
            {children}
        </button>);
};
interface DropdownProps {
    dropdownButtonState: DropdownButtonState;
    accessibleName: string;
}
const Dropdown = ({ dropdownButtonState, accessibleName, children }: PropsWithChildren<DropdownProps>) => {
    return (<Popover placement={"bottom-start"} anchorEl={dropdownButtonState.buttonElement} open={dropdownButtonState.isOpen} onClose={dropdownButtonState.closeDropdown} elementId={dropdownButtonState.dropdownAttributes.dropdownId} accessibleName={accessibleName} accessibleRole={dropdownButtonState.dropdownAttributes.dropdownAriaAttributes.role} boundaryPadding={16}>
            <div ref={dropdownButtonState.setDropdownRef} className={popoverContentStyles}>
                {children}
            </div>
        </Popover>);
};
export const DropdownButton = {
    Dropdown,
    Button,
};
const popoverContentStyles = css({
    backgroundColor: themeTokens.color.menuList.background.default,
    borderRadius: borderRadius.small,
    width: "min(29rem, calc(100vw - 32px))",
    height: "33.75rem",
    pointerEvents: "all",
});
