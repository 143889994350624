import type { InsightsDataSeriesResource } from "./insightsMetrics";
export enum InsightsTrendDirection {
    None = "None",
    Down = "Down",
    Steady = "Steady",
    Up = "Up"
}
export interface OverallTrend<T extends string | number> {
    Direction: InsightsTrendDirection;
    Ratio: number | null;
    Previous: T | null;
    Current: T | null;
}
export interface SparklineTrendResource<TVal extends string | number> extends OverallTrend<TVal> {
    Id: string;
    Name: string;
    DataPoints: TVal[];
}
export type OverallDurationTrend = OverallTrend<string>;
export type OverallNumberTrend = OverallTrend<number>;
interface TrendDeploymentBase {
    Id: string;
    CompletedTime: string;
    EnvironmentId: string;
    EnvironmentName: string;
    ProjectId: string;
    ProjectName: string;
    ReleaseId: string;
    ReleaseVersion: string;
    TenantId: string | null;
    TenantName: string | null;
}
export interface LeadTimeDeployment extends TrendDeploymentBase {
    LeadTime: string;
    LeadTimeCalculatedFromVersion: string | null;
}
export interface TenantDeployedAgo {
    TenantId: string;
    TenantName: string;
    Id: string;
    ReleaseId: string;
    ReleaseVersion: string;
    CompletedTime: string;
    CompletedAgo: string;
}
export interface SparklineTrendPair<T extends string | number> {
    Up: SparklineTrendResource<T>[];
    Down: SparklineTrendResource<T>[];
}
export interface SparklineTrends<T extends string | number> {
    EnvironmentTrends: SparklineTrendPair<T> | null;
    ProjectTrends: SparklineTrendPair<T> | null;
    TenantTrends: SparklineTrendPair<T> | null;
}
export interface LeadTimeTrends extends SparklineTrends<string> {
    OverallTrend: OverallDurationTrend;
    Longest: LeadTimeDeployment[];
    TenantsNotRecentlyDeployedTo: TenantDeployedAgo[];
}
export interface DeploymentFrequencyDeployment extends TrendDeploymentBase {
    TimeSincePreviouslySuccessfulDeployment: string;
}
export interface DeploymentFrequencyTrends extends SparklineTrends<number> {
    OverallTrend: OverallNumberTrend;
    ProjectsWithLowestFrequency: SparklineTrendResource<number>[] | null;
    LongestTimeSincePreviouslySuccessfulDeployment: DeploymentFrequencyDeployment[];
    MostRecentSuccessful: DeploymentFrequencyDeployment[];
}
export interface FailureRateDeployment extends TrendDeploymentBase {
    State: string;
    HadGuidedFailure: boolean;
}
export interface ChangeFailureRateTrends extends SparklineTrends<number> {
    OverallTrend: OverallNumberTrend;
    RecentFailures: FailureRateDeployment[];
}
export interface MeanTimeToRecoveryDeployment extends TrendDeploymentBase {
    DeploymentsUntilRecovery: string | null;
    TimeToRecovery: string | null;
    RecoveredFromFailedReleaseVersion: string | null;
}
export interface MeanTimeToRecoveryTrends {
    OverallTrend: OverallDurationTrend;
    LongestTimeToRecovery: MeanTimeToRecoveryDeployment[];
    LargestNumberOfDeploymentsUntilRecovery: MeanTimeToRecoveryDeployment[];
}
export interface InsightsTrends {
    ChangeFailureRate: ChangeFailureRateTrends;
    DeploymentFrequency: DeploymentFrequencyTrends;
    LeadTime: LeadTimeTrends;
    MeanTimeToRecovery: MeanTimeToRecoveryTrends;
}
export type InsightsTrendKey = keyof InsightsTrends;
export interface GetInsightsForReportBffResponse extends InsightsTrends {
    Series: InsightsDataSeriesResource[];
}
