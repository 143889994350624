/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { ActionBulkUpdateResultResource, ActionsUpdateProcessResource, ActionTemplateCategoryResource, ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResource } from "../resources";
import type { ActionProperties } from "../resources/actionProperties";
import type { ActionTemplateSearchResource } from "../resources/actionTemplateSearchResource";
import type { CommunityActionTemplateResource } from "../resources/communityActionTemplateResource";
import { BasicRepository } from "./basicRepository";
export class ActionTemplateRepository extends BasicRepository<ActionTemplateResource, ActionTemplateResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("ActionTemplates", client, requestContext);
    }
    getUsage(template: Partial<ActionTemplateResource>, args?: any): Promise<ActionTemplateUsageResource[]> {
        return this.client.get(this.requestContext, template.Links!["Usage"], args);
    }
    getByCommunityTemplate(communityTemplate: CommunityActionTemplateResource) {
        const allArgs = { ...{}, ...{ id: communityTemplate.Id } };
        return this.client.get<ActionTemplateResource>(this.requestContext, communityTemplate.Links["InstalledTemplate"], allArgs);
    }
    search(args?: {
        type?: string;
    }): Promise<ActionTemplateSearchResource[]> {
        return this.client.get<ActionTemplateSearchResource[]>(this.requestContext, this.client.getLink("ActionTemplatesSearch"), args);
    }
    updateActions(actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], defaults: ActionProperties = {}, overrides: ActionProperties = {}) {
        const resource: ActionUpdateResource = {
            ActionsToUpdate: actionsToUpdate,
            Overrides: overrides || {},
            DefaultPropertyValues: defaults || {},
            Version: actionTemplate.Version!,
        };
        return this.client.post(this.requestContext, actionTemplate.Links!["ActionsUpdate"], resource);
    }
    bulkUpdateActions(actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], defaults: ActionProperties = {}, overrides: ActionProperties = {}) {
        const resource: ActionUpdateResource = {
            ActionsToUpdate: actionsToUpdate,
            Overrides: overrides || {},
            DefaultPropertyValues: defaults || {},
            Version: actionTemplate.Version!,
        };
        return this.client.post<ActionBulkUpdateResultResource>(this.requestContext, actionTemplate.Links!["ActionsBulkUpdate"], resource);
    }
    getVersion(actionTemplate: ActionTemplateResource, version: any): Promise<ActionTemplateResource> {
        return this.client.get(this.requestContext, actionTemplate.Links["Versions"], { version });
    }
    categories(): Promise<ActionTemplateCategoryResource[]> {
        return this.client.get<ActionTemplateCategoryResource[]>(this.requestContext, this.client.getLink("ActionTemplatesCategories"));
    }
}
