import type { Client, RequestContext } from "../client";
import type { DashboardResource } from "../resources/dashboardResource";
import type { DetailedProjectsDashboardResource } from "../resources/detailedProjectsDashboardResource";
import type { InitialProjectsDashboardResource, ProjectsPageResource } from "../resources/index";
export class DashboardRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    getDeploymentsCountedByWeek(projectIds: string[]) {
        return this.client.get(this.requestContext, this.client.getLink("Reporting/DeploymentsCountedByWeek"), { projectIds: projectIds.join(",") });
    }
    getDashboard(dashboardFilter?: DashboardFilter): Promise<DashboardResource> {
        return this.client.get<DashboardResource>(this.requestContext, this.client.getLink("Dashboard"), dashboardFilter);
    }
    getTenantedProjectDashboard(dashboardFilter: TenantedProjectDashboardFilter): Promise<GetTenantedProjectDashboardBffResponse> {
        return this.client.get<GetTenantedProjectDashboardBffResponse>(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/tenanted-dashboard{?releaseId,showAll,selectedTenants,selectedTags,highestLatestVersionPerProjectAndEnvironment,skip,take}", dashboardFilter);
    }
    getInitialProjectsDashboard(dashboardFilter: ProjectsDashboardFilter): Promise<InitialProjectsDashboardResource> {
        return this.client.get<InitialProjectsDashboardResource>(this.requestContext, "~/bff/spaces/{spaceId}/projects/dashboard/initial{?skip,take,projectOrGroupPartialName}", dashboardFilter);
    }
    getDetailedProjectsDashboard(dashboardFilter: ProjectsDashboardFilter): Promise<DetailedProjectsDashboardResource> {
        return this.client.get<DetailedProjectsDashboardResource>(this.requestContext, "~/bff/spaces/{spaceId}/projects/dashboard/detailed{?skip,take,projectOrGroupPartialName}", dashboardFilter);
    }
    getProjectsPage(dashboardFilter: ProjectsDashboardFilter): Promise<ProjectsPageResource> {
        return this.client.get<ProjectsPageResource>(this.requestContext, "~/bff/spaces/{spaceId}/projects{?skip,take,projectOrGroupPartialName}", dashboardFilter);
    }
}
export interface GetTenantedProjectDashboardBffResponse {
    Dashboard: DashboardResource;
    TotalItems: number;
}
export type DashboardFilter = {
    projectId?: string;
    releaseId?: string;
    showAll?: boolean;
    highestLatestVersionPerProjectAndEnvironment?: boolean;
};
export type ProjectsDashboardFilter = {
    skip: number;
    take: number;
    projectOrGroupPartialName?: string;
};
export type TenantedProjectDashboardFilter = {
    projectId: string;
    releaseId?: string;
    showAll?: boolean;
    selectedTenants?: string[];
    selectedTags?: string[];
    highestLatestVersionPerProjectAndEnvironment?: boolean;
    skip: number;
    take: number;
};
