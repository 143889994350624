import type { ICommitCommand } from "./commitCommand";
import type { GuidedFailureMode, ConnectivityPolicy } from "./deploymentSettingsResource";
import type { NamedResource } from "./namedResource";
import type { RunbookRetentionPeriod } from "./retentionPeriod";
import type { TenantedDeploymentMode } from "./tenantedDeploymentMode";
interface RunbookResourceLinks {
    Self: string;
    Template: string;
    Project: string;
    RunbookSnapshotTemplate: string;
    RunbookRunTemplate: string;
    RunbookRunPreview: string;
    RunbookRunTenantPreview: string;
    RunbookSnapshots: string;
    CreateRunbookRun: string;
    RunbookProcesses: string;
    Progression: string;
    TaskRunDashboardItemsTemplate: string;
    RunbookEnvironments: string;
}
export type ModifyRunbookCommand = RunbookResource & ICommitCommand;
export interface RunbookResource extends NamedResource<RunbookResourceLinks> {
    Description: string;
    RunbookProcessId: string;
    PublishedRunbookSnapshotId?: string;
    ProjectId: string;
    SpaceId: string;
    ConnectivityPolicy: ConnectivityPolicy;
    MultiTenancyMode: TenantedDeploymentMode;
    EnvironmentScope: RunbookEnvironmentScope;
    Environments: string[];
    DefaultGuidedFailureMode: GuidedFailureMode;
    RunRetentionPolicy: RunbookRetentionPeriod;
    ForcePackageDownload: boolean;
}
export type NewRunbookResource = Omit<RunbookResource, "Id" | "Links" | "SpaceId" | "RunbookProcessId" | "PublishedRunbookSnapshotId" | "ConnectivityPolicy" | "Environments">;
export enum RunbookEnvironmentScope {
    All = "All",
    Specified = "Specified",
    FromProjectLifecycles = "FromProjectLifecycles"
}
