/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { GetProjectsOnboardingBffResponse } from "../resources/getProjectsOnboardingBffResponse";
import type { UserOnboardingResource } from "../resources/userOnboardingResource";
import { BasicRepository } from "./basicRepository";
export type GetOrCreateUserOnboardingResponse = {
    UserOnboardingResource: UserOnboardingResource;
};
export type ModifyUserOnboardingResponse = {
    UserOnboardingResource: UserOnboardingResource;
};
export class UserOnboardingRepository extends BasicRepository<UserOnboardingResource, UserOnboardingResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("UserOnboarding", client, requestContext);
    }
    async getForCurrentUser() {
        try {
            return await this.client.put<GetOrCreateUserOnboardingResponse>(this.requestContext, `~/bff/onboarding`);
        }
        catch (ex) {
            if (ex.StatusCode === 403) {
                return null;
            }
            throw ex;
        }
    }
    modifyForCurrentUser(resource: UserOnboardingResource) {
        return this.client.put(this.requestContext, `~/bff/onboarding/${resource.Id}`, resource);
    }
    getProjectsOnboardingForCurrentUser() {
        return this.client.get<GetProjectsOnboardingBffResponse>(this.requestContext, `~/bff/spaces/{spaceId}/onboarding/projects`);
    }
}
