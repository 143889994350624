import { createPageRoute } from "./PageRouteDefinition";
import type { PageRouteDefinition } from "./PageRouteDefinition";
import type { RouteTemplate } from "./RouteTemplate";
import { routeTemplate } from "./RouteTemplate";
import { createRedirect } from "./createRedirect";
import type { RedirectRouteDefinition } from "./createRedirect";
import type { UnknownQueryParam } from "./query/QueryStringParam";
// N.B. These types are a work in progress. They will soon replace some of the other types we have building up routes and partial routes in the new routing infrastructure.
export function createRouteSegment<RouteParams, ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>>(routeSegment: RouteTemplate<RouteParams>, children: ChildRouteTreeNodes): MappedRouteSegment<RouteParams, ChildRouteTreeNodes> {
    const { pages, routeSegments, redirects } = splitPagesAndRouteSegmentAndRedirects(children);
    const mappedPages: PrefixPagesWithParent<RouteParams, ExtractPages<ChildRouteTreeNodes>> = prefixPagesWithParent(routeSegment, pages);
    const mappedChildRoutes: PrefixChildRouteSegmentsWithParent<RouteParams, ExtractChildRouteSegments<ChildRouteTreeNodes>> = prefixRouteSegmentsWithParent(routeSegment, routeSegments);
    const mappedRedirects: PrefixRedirectsWithParent<RouteParams, ExtractRedirects<ChildRouteTreeNodes>> = prefixRedirectsWithParent(routeSegment, redirects);
    return {
        partialRoute: routeSegment,
        childRouteSegments: mappedChildRoutes,
        pages: mappedPages,
        redirects: mappedRedirects,
    };
}
export type RouteSegment<RouteParams, ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint, Redirects extends RedirectsConstraint> = {
    partialRoute: RouteTemplate<RouteParams>;
    childRouteSegments: ChildRoutes;
    pages: Pages;
    redirects: Redirects;
};
type MappedRouteSegment<RouteParams, ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = RouteSegment<RouteParams, PrefixChildRouteSegmentsWithParent<RouteParams, ExtractChildRouteSegments<ChildRouteTreeNodes>>, PrefixPagesWithParent<RouteParams, ExtractPages<ChildRouteTreeNodes>>, PrefixRedirectsWithParent<RouteParams, ExtractRedirects<ChildRouteTreeNodes>>>;
type ExtractChildRouteSegments<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in SegmentKeysOnly<ChildRouteTreeNodes>]: ChildRouteTreeNodes[K] extends RouteSegmentConstraint ? ChildRouteTreeNodes[K] : never;
};
type SegmentKeysOnly<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in keyof ChildRouteTreeNodes]: ChildRouteTreeNodes[K] extends RouteSegmentConstraint ? K : never;
}[keyof ChildRouteTreeNodes];
type ExtractPages<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in PageKeysOnly<ChildRouteTreeNodes>]: ChildRouteTreeNodes[K] extends PageConstraint ? ChildRouteTreeNodes[K] : never;
};
type PageKeysOnly<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in keyof ChildRouteTreeNodes]: ChildRouteTreeNodes[K] extends PageConstraint ? K : never;
}[keyof ChildRouteTreeNodes];
type ExtractRedirects<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in RedirectKeysOnly<ChildRouteTreeNodes>]: ChildRouteTreeNodes[K] extends RedirectConstraint ? ChildRouteTreeNodes[K] : never;
};
type RedirectKeysOnly<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>> = {
    [K in keyof ChildRouteTreeNodes]: ChildRouteTreeNodes[K] extends RedirectConstraint ? K : never;
}[keyof ChildRouteTreeNodes];
type PrefixChildRouteSegmentsWithParent<ParentRouteParams, ChildRouteSegments extends RouteSegmentsConstraint> = {
    [K in keyof ChildRouteSegments]: PrefixChildRouteSegmentWithParent<ParentRouteParams, ChildRouteSegments[K]>;
};
type PrefixChildRouteSegmentWithParent<ParentRouteParams, TRouteSegment extends RouteSegmentConstraint> = TRouteSegment extends RouteSegment<infer RouteSegmentParams, infer PartialRouteChildRoutes, infer Pages, infer Redirects> ? RouteSegment<ParentRouteParams & RouteSegmentParams, PrefixChildRouteSegmentsWithParent<ParentRouteParams & RouteSegmentParams, PartialRouteChildRoutes>, PrefixPagesWithParent<ParentRouteParams & RouteSegmentParams, Pages>, PrefixRedirectsWithParent<ParentRouteParams & RouteSegmentParams, Redirects>> : never;
type PrefixPagesWithParent<ParentRouteParams, Pages extends PagesConstraint> = {
    [K in keyof Pages]: PrefixPageWithParent<ParentRouteParams, Pages[K]>;
};
type PrefixRedirectsWithParent<ParentRouteParams, Redirects extends RedirectsConstraint> = {
    [K in keyof Redirects]: PrefixRedirectWithParent<ParentRouteParams, Redirects[K]>;
};
type PrefixRedirectWithParent<ParentRouteParams, Redirect extends RedirectConstraint> = Redirect extends RedirectRouteDefinition<infer RouteParams> ? RedirectRouteDefinition<ParentRouteParams & RouteParams> : never;
type PrefixPageWithParent<ParentRouteParams, Page extends PageConstraint> = Page extends PageRouteDefinition<infer RouteParams, infer QueryParams> ? PageRouteDefinition<ParentRouteParams & RouteParams, QueryParams> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageConstraint = PageRouteDefinition<any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteSegmentConstraint = RouteSegment<any, any, any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RedirectConstraint = RedirectRouteDefinition<any>;
type RouteSegmentsConstraint = Record<string, RouteSegmentConstraint>;
type PagesConstraint = Record<string, PageConstraint>;
type RedirectsConstraint = Record<string, RedirectConstraint>;
function splitPagesAndRouteSegmentAndRedirects<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint | RedirectConstraint>>(children: ChildRouteTreeNodes) {
    const pages = Object.entries(children)
        .filter(([key, node]) => isPage(node))
        .reduce<ExtractPages<ChildRouteTreeNodes>>((p, [key, node]) => ({ ...p, [key]: node }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ExtractPages<ChildRouteTreeNodes>);
    const routeSegments = Object.entries(children)
        .filter(([key, node]) => isRouteSegment(node))
        .reduce<ExtractChildRouteSegments<ChildRouteTreeNodes>>((p, [key, node]) => ({ ...p, [key]: node }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ExtractChildRouteSegments<ChildRouteTreeNodes>);
    const redirects = Object.entries(children)
        .filter(([key, node]) => isRedirect(node))
        .reduce<ExtractRedirects<ChildRouteTreeNodes>>((p, [key, node]) => ({ ...p, [key]: node }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ExtractRedirects<ChildRouteTreeNodes>);
    return { pages, routeSegments, redirects };
}
function isPage(node: PageConstraint | RouteSegmentConstraint | RedirectConstraint): node is PageConstraint {
    return "queryParameters" in node;
}
function isRedirect(node: PageConstraint | RouteSegmentConstraint | RedirectConstraint): node is RedirectConstraint {
    return "isRedirect" in node && node.isRedirect;
}
function isRouteSegment(node: PageConstraint | RouteSegmentConstraint | RedirectConstraint): node is RouteSegmentConstraint {
    return !isPage(node) && !isRedirect(node);
}
function prefixPagesWithParent<RouteParams, Pages extends PagesConstraint>(parentPrefix: RouteTemplate<RouteParams>, pages: Pages): PrefixPagesWithParent<RouteParams, Pages> {
    return Object.entries(pages).reduce<PrefixPagesWithParent<RouteParams, Pages>>((p, [key, page]) => {
        return {
            ...p,
            [key]: prefixPage(parentPrefix, page),
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as PrefixPagesWithParent<RouteParams, Pages>);
}
function prefixPage<ParentRouteParams, ChildRouteParams, QueryParams extends UnknownQueryParam[]>(parentPrefix: RouteTemplate<ParentRouteParams>, page: PageRouteDefinition<ChildRouteParams, QueryParams>): PageRouteDefinition<ParentRouteParams & ChildRouteParams, QueryParams> {
    const template: RouteTemplate<ParentRouteParams & ChildRouteParams> = routeTemplate `${parentPrefix}${page.template}`;
    return createPageRoute<ParentRouteParams & ChildRouteParams, QueryParams>(template, page.queryParameters);
}
function prefixRouteSegmentsWithParent<RouteParams, ChildRoutes extends RouteSegmentsConstraint>(parentPrefix: RouteTemplate<RouteParams>, childRoutes: ChildRoutes): PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes> {
    return Object.entries(childRoutes).reduce<PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes>>((p, [key, routeSegment]) => {
        return {
            ...p,
            [key]: prefixRouteSegment(parentPrefix, routeSegment),
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes>);
}
function prefixRouteSegment<ParentRouteParams, ChildRouteParams, ChildRouteSegments extends RouteSegmentsConstraint, Pages extends PagesConstraint, Redirects extends RedirectsConstraint>(prefix: RouteTemplate<ParentRouteParams>, routeSegment: RouteSegment<ChildRouteParams, ChildRouteSegments, Pages, Redirects>): RouteSegment<ParentRouteParams & ChildRouteParams, PrefixChildRouteSegmentsWithParent<ParentRouteParams, ChildRouteSegments>, PrefixPagesWithParent<ParentRouteParams, Pages>, PrefixRedirectsWithParent<ParentRouteParams, Redirects>> {
    const { partialRoute, childRouteSegments, pages, redirects } = routeSegment;
    return {
        partialRoute: routeTemplate `${prefix}${partialRoute}`,
        childRouteSegments: prefixRouteSegmentsWithParent(prefix, childRouteSegments),
        pages: prefixPagesWithParent(prefix, pages),
        redirects: prefixRedirectsWithParent(prefix, redirects),
    };
}
function prefixRedirectsWithParent<RouteParams, Redirects extends RedirectsConstraint>(parentPrefix: RouteTemplate<RouteParams>, redirects: Redirects): PrefixRedirectsWithParent<RouteParams, Redirects> {
    return Object.entries(redirects).reduce<PrefixRedirectsWithParent<RouteParams, Redirects>>((p, [key, redirect]) => {
        return {
            ...p,
            [key]: createRedirect(routeTemplate `${parentPrefix}${redirect.completeRoute.template}`),
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as PrefixRedirectsWithParent<RouteParams, Redirects>);
}
