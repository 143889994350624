import React from "react";
type PossibleRef<T> = React.Ref<T> | undefined;
/**
 * Set a given ref to a given value
 * This utility takes care of different types of refs: callback refs and RefObject(s)
 * @param ref
 * @param value
 */
function setRef<T>(ref: PossibleRef<T>, value: T) {
    if (typeof ref === "function") {
        ref(value);
    }
    else if (ref !== null && ref !== undefined) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        (ref as React.MutableRefObject<T>).current = value;
    }
}
/**
 * A utility to compose multiple refs together
 * Accepts callback refs and RefObject(s)
 * @param {...any} refs
 */
function composeRefs<T>(...refs: PossibleRef<T>[]) {
    return (node: T) => refs.forEach((ref) => setRef(ref, node));
}
/**
 * A custom hook that composes multiple refs
 * Accepts callback refs and RefObject(s)
 * @param {...any} refs
 */
function useComposedRefs<T>(...refs: PossibleRef<T>[]) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useCallback(composeRefs(...refs), refs);
}
export { composeRefs, useComposedRefs };
