import type { Client, RequestContext } from "../client";
import type { TenantVariableResource } from "../resources/tenantVariableResource";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type TenantVariableListArgs = {
    projectId?: string;
} & ListArgs;
type TenantVariableAllArgs = {
    projectId?: string;
} & AllArgs;
export class TenantVariableRepository extends BasicRepository<TenantVariableResource, TenantVariableResource, TenantVariableListArgs, TenantVariableAllArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("TenantVariables", client, requestContext);
    }
    async getOnlyProjectScoped(projectId: string): Promise<TenantVariableResource[]> {
        const response: GetProjectTenantVariablesBffResponse = await this.client.get(this.requestContext, "~/bff/spaces/{spaceId}/projects/{projectId}/tenantvariables", { projectId });
        return response.TenantVariableResources;
    }
}
export interface GetProjectTenantVariablesBffResponse {
    TenantVariableResources: TenantVariableResource[];
}
