/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { typeSafeHasOwnProperty } from "../utils";
import type { ReleaseChanges } from "./deploymentResource";
import type { Form } from "./form";
import type { MachineModelHealthStatus } from "./machineResource";
import type { ReferenceDataItem } from "./referenceDataItem";
import type { ResourceWithId, ResourceWithLinks } from "./resource";
export interface DeploymentPreviewBffRequest {
    EnvironmentIds: string[];
    TenantIds: string[];
}
export interface DeploymentPreviewRequests {
    DeploymentPreviews: DeploymentPreviewRequest[];
}
export interface DeploymentPreviewRequest {
    EnvironmentId: string;
    TenantId?: string;
}
export interface MachineDeploymentPreview extends ResourceWithId {
    HasLatestCalamari: boolean;
    Name: string;
    HealthStatus?: MachineModelHealthStatus;
}
export interface DeploymentTemplateStep {
    ActionId: string;
    ActionName: string;
    ActionNumber: string;
    Roles: string[];
    Machines: MachineDeploymentPreview[];
    CanBeSkipped: boolean;
    IsDisabled: boolean;
    HasNoApplicableMachines: boolean;
    UnavailableMachines: ReferenceDataItem[];
    ExcludedMachines: ReferenceDataItem[];
}
export interface DeploymentPreviewBaseResource extends ResourceWithLinks {
    StepsToExecute: DeploymentTemplateStep[];
    Form: Form;
    UseGuidedFailureModeByDefault: boolean;
}
export interface GetCreateDeploymentDeploymentPreviewsBffResponse {
    Previews: GetBffDeploymentPreview[];
}
export interface GetBffDeploymentPreview extends DeploymentPreviewBaseResource {
    WorkItemsCount: number;
    ReleaseCount: number;
    ChangesMarkdown: string;
}
export interface DeploymentPreviewResource extends DeploymentPreviewBaseResource {
    Changes: ReleaseChanges[];
    ChangesMarkdown: string;
}
export function isDeploymentPreviewBffResource(resource: NonNullable<DeploymentPreviewBaseResource>): resource is GetBffDeploymentPreview {
    const converted = resource as GetBffDeploymentPreview;
    return converted.WorkItemsCount !== undefined && typeSafeHasOwnProperty(converted, "WorkItemsCount") && converted.ReleaseCount !== undefined && typeSafeHasOwnProperty(converted, "ReleaseCount");
}
export function isRunbookRunPreviewResource(resource: NonNullable<DeploymentPreviewBaseResource>): resource is RunbookRunPreviewResource {
    const converted = resource as DeploymentPreviewResource;
    return converted.Changes !== undefined && typeSafeHasOwnProperty(converted, "Changes");
}
export type RunbookRunPreviewRequests = DeploymentPreviewRequests;
export type RunbookRunPreviewResource = DeploymentPreviewResource;
