import type { Client, RequestContext } from "../client";
import type { SigningKeyResource } from "../resources/signingKeyResource";
import { BasicRepository } from "./basicRepository";
export class SigningKeyRepository extends BasicRepository<SigningKeyResource, SigningKeyResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("SigningKeys", client, requestContext);
    }
    // Can't use `list()` as it wants to return a `ResourceCollection`
    async all(): Promise<SigningKeyResource[]> {
        const res: {
            SigningKeys: SigningKeyResource[];
        } = await this.client.get(this.requestContext, this.client.getLink(this.collectionLinkName));
        return res.SigningKeys ?? [];
    }
    rotate(): Promise<void> {
        return this.client.post(this.requestContext, this.client.getLink("SigningKeyRotate"));
    }
    revoke(id: string): Promise<void> {
        return this.client.post(this.requestContext, this.client.getLink("SigningKeyRevoke"), null, { id });
    }
}
