export interface GitHubAppConnections {
    Connections: GitHubAppConnectionSummary[];
}
export interface GitHubAppConnectionSummary {
    Id: string;
    Installation?: GitHubAppInstallation;
}
export interface GitHubAppInstallations {
    Installations: GitHubAppInstallation[];
}
export enum GitHubAccountType {
    User = "User",
    Organization = "Organization"
}
export interface GitHubAppInstallation {
    InstallationId: string;
    AccountId: string;
    AccountLogin: string;
    AccountAvatarUrl: string;
    AccountType: GitHubAccountType;
}
export interface GitHubAppRepositories {
    Repositories: GitHubRepository[];
}
export interface PagedGitHubAppRepositories extends GitHubAppRepositories {
    ItemsPerPage: number;
    NumberOfPages: number;
    TotalResults: number;
}
export interface GitHubRepository {
    RepositoryId: string;
    RepositoryName: string;
    IsAdmin: boolean;
    IsPrivate: boolean;
    Visibility: string;
    Language?: string;
    GitUrl: string;
    DefaultBranch: string;
}
export interface GitHubUnknownRepository {
    RepositoryId: string;
    RepositoryName: string;
}
export interface GitHubAppConnectionResource {
    Id: string;
    Installation: GitHubAppInstallation;
    Repositories: GitHubRepository[];
    UnknownRepositories: GitHubUnknownRepository[];
    Status: string;
    StatusUserMessage?: string;
}
export interface GitHubAppAuthorizationStatus {
    IsAuthorized: boolean;
    CanAuthorize: boolean;
    UserDetails?: GitHubAppAuthorizationStatusUserDetails;
}
export interface GitHubAppAuthorizationStatusUserDetails {
    Login?: string;
    Name?: string;
    PrimaryEmail?: string;
    AvatarUrl?: string;
    TokenValidTo: string;
    RefreshTokenValidTo: string;
}
export class GitHubConnectionStatus {
    public static readonly Connected = "Connected";
    public static readonly Disconnected = "Disconnected";
    public static readonly Suspended = "Suspended";
    public static readonly Missing = "Missing";
}
