import type { Client, RequestContext } from "../client";
import type { NamedReferenceItem } from "../resources/namedReferenceItem";
export class ExternalSecurityGroupRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    search(url: string, partialName: string): Promise<NamedReferenceItem[]> {
        return this.client.get(this.requestContext, url, { partialName });
    }
}
