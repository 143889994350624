import { css } from "@emotion/css";
import { useOctopusPathResolver } from "@octopusdeploy/octopus-react-client";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface SpaceLogoProps {
    space: SpaceResource;
}
export const SpaceLogo = ({ space }: SpaceLogoProps) => {
    const resolvePath = useOctopusPathResolver();
    return <img alt={`Space ${space.Name} Logo`} className={spaceLogoStyles} src={resolvePath(space.Links.Logo)}/>;
};
const spaceLogoStyles = css({
    width: "100%",
    height: "100%",
});
