import type { UserPermissions } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import React, { useContext } from "react";
export function useOctopusSessionPermissions() {
    const currentPermissions = useContext(OctopusSessionPermissionsContext);
    if (currentPermissions === undefined) {
        throw new Error("The current session permissions context was not provided via OctopusSessionPermissionsProvider");
    }
    if (currentPermissions === null) {
        throw new Error("A current user permissions for the session is required but was null.");
    }
    return currentPermissions;
}
export interface OctopusSessionPermissionsProviderProps {
    currentPermissions: UserPermissions | null;
}
export function OctopusSessionPermissionsProvider({ currentPermissions, children }: PropsWithChildren<OctopusSessionPermissionsProviderProps>) {
    return <OctopusSessionPermissionsContext.Provider value={currentPermissions}>{children}</OctopusSessionPermissionsContext.Provider>;
}
const OctopusSessionPermissionsContext = React.createContext<UserPermissions | null | undefined>(undefined);
