import type { Client, RequestContext } from "../client";
import type { DeploymentSettingsResource, ModifyDeploymentSettingsCommand, ProjectResource, GitRefResource } from "../resources";
export class DeploymentSettingsRepository {
    readonly resourceLink = "DeploymentSettings";
    constructor(private readonly client: Client, private readonly project: ProjectResource, private readonly gitRef: GitRefResource | undefined, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    get(): Promise<DeploymentSettingsResource> {
        if (this.project.IsVersionControlled && this.gitRef !== undefined) {
            return this.client.get(this.requestContext, this.gitRef.Links[this.resourceLink]);
        }
        return this.client.get(this.requestContext, this.project.Links[this.resourceLink]);
    }
    modify(deploymentSettings: ModifyDeploymentSettingsCommand): Promise<DeploymentSettingsResource> {
        return this.client.update(this.requestContext, deploymentSettings.Links.Self, deploymentSettings);
    }
}
