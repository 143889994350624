import type { Client, RequestContext } from "../client";
import type { ParseTaskLogsResponse } from "../resources/parseTaskLogsResponse";
export class DiagnosticsRepository {
    private client: Client;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    async parseTaskLogs(files: File[]) {
        const fd = new FormData();
        files.map((f) => fd.append(f.name, f));
        return await this.client.post<ParseTaskLogsResponse>(this.requestContext, "~/bff/diagnostics/parse-task-logs", fd);
    }
}
