import type { Client, RequestContext } from "../client";
import type { NewTriggerResource, TriggerResource } from "../resources";
import type { TriggerExecutionResource } from "../resources/triggerExecutionResource";
import { BasicRepository } from "./basicRepository";
export class ProjectTriggerRepository extends BasicRepository<TriggerResource, NewTriggerResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("ProjectTriggers", client, requestContext);
    }
    getLastExecution(projectTrigger: TriggerResource): Promise<TriggerExecutionResource> {
        return this.client.get<TriggerExecutionResource>(this.requestContext, `~/bff/spaces/{spaceId}/projecttriggerslastexecution/{projectTriggerId}`, {
            spaceId: projectTrigger.SpaceId,
            projectTriggerId: projectTrigger.Id,
        });
    }
}
