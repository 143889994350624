import type { Client, RequestContext } from "../client";
import type { LifecycleResource } from "../resources";
import type { ProjectResource } from "../resources/projectResource";
import { BasicRepository } from "./basicRepository";
export class LifecycleRepository extends BasicRepository<LifecycleResource, LifecycleResource> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Lifecycles", client, requestContext);
    }
    preview(lifecycle: LifecycleResource): Promise<LifecycleResource> {
        return this.client.get(this.requestContext, lifecycle.Links["Preview"]);
    }
    previews(ids: string[]): Promise<LifecycleResource[]> {
        return this.client.get(this.requestContext, this.client.getLink("LifecyclePreviews"), { ids: ids.join(",") });
    }
    projects(lifecycle: LifecycleResource) {
        return this.client.get<ProjectResource[]>(this.requestContext, lifecycle.Links["Projects"]);
    }
}
