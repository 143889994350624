/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client, RequestContext } from "../client";
import type { ScheduledTaskDetailsResource } from "../resources";
import { BasicRepository } from "./basicRepository";
interface DetailsArgs {
    tail: number;
    verbose: boolean;
}
export class SchedulerRepository extends BasicRepository<any, any> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Scheduler", client, requestContext);
    }
    getDetails(name: string, options?: DetailsArgs) {
        const args = { ...options, name };
        return this.client.get<ScheduledTaskDetailsResource>(this.requestContext, this.client.getLink("Scheduler"), args);
    }
}
