import type { Client, RequestContext } from "../client";
import type { OctopusPackageVersionBuildInformationMappedResource } from "../resources";
import type { AllArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type BuildInformationListArgs = {
    take?: number;
    skip?: number;
    filter?: string;
    packageId?: string;
    latest?: boolean;
    partialPackageId?: string;
};
type BuildInformationGetArgs = {};
export class BuildInformationRepository extends BasicRepository<OctopusPackageVersionBuildInformationMappedResource, OctopusPackageVersionBuildInformationMappedResource, BuildInformationListArgs, AllArgs, BuildInformationGetArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("BuildInformation", client, requestContext);
    }
    deleteMany(ids: string[]) {
        return this.client.del(this.requestContext, this.client.getLink("BuildInformationBulk"), null, { ids });
    }
}
