import type { Client, RequestContext } from "../client";
import type { FeatureToggleResource, ProjectResource, ToggleStrategy, TenantTargetingStrategy, ResourceWithId } from "../resources";
export interface ListFeatureTogglesResponse {
    Features: FeatureToggleResource[];
}
export interface ModifyFeatureToggleCommand {
    Id: string;
    ProjectId: string;
    SpaceId: string;
    Name: string;
    Slug: string;
    Toggles: ToggleDefinition[];
}
export type CreateFeatureToggleCommand = Omit<ModifyFeatureToggleCommand, "Id" | "Slug" | "SpaceId">;
interface ModifyFeatureToggleResponse extends ResourceWithId {
    ProjectId: string;
    SpaceId: string;
    Name: string;
    Slug: string;
    Toggles: ToggleDefinition[];
}
interface Segment {
    Key: string;
    Value: string;
}
interface ToggleDefinition {
    DeploymentEnvironmentId: string;
    IsEnabled: boolean;
    ToggleStrategy: ToggleStrategy;
    TenantTargetingStrategy: TenantTargetingStrategy;
    TenantIds: string[];
    RolloutPercentage: number | null;
    ExcludedTenantIds: string[];
    Segments: Segment[];
}
export class FeatureToggleRepository {
    protected client: Client;
    protected apiBaseRoute: string = `~/api/spaces/{spaceId}/projects/{projectId}/featuretoggles`;
    constructor(client: Client, private readonly requestContext: RequestContext) {
        this.client = client;
    }
    async list(project: ProjectResource) {
        return this.client.get<ListFeatureTogglesResponse>(this.requestContext, this.apiBaseRoute, {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
    async get(project: ProjectResource, featureToggleSlug: string) {
        return this.client.get<FeatureToggleResource>(this.requestContext, `${this.apiBaseRoute}/{featureToggleSlug}`, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            featureToggleSlug,
        });
    }
    async create(project: ProjectResource, command: CreateFeatureToggleCommand): Promise<FeatureToggleResource> {
        const response = await this.client.create<CreateFeatureToggleCommand, ModifyFeatureToggleResponse>(this.requestContext, this.apiBaseRoute, command, {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
        return await this.get(project, response.Slug);
    }
    async modify(project: ProjectResource, resource: ModifyFeatureToggleCommand) {
        const response = await this.client.update<ModifyFeatureToggleCommand>(this.requestContext, this.apiBaseRoute, resource, {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
        return await this.get(project, response.Slug);
    }
    async delete(project: ProjectResource, featureToggleId: string) {
        await this.client.del(this.requestContext, `${this.apiBaseRoute}/{featureToggleId}`, null, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            featureToggleId,
        });
    }
}
