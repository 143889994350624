import type { Client, RequestContext } from "../client";
import type { GitRefResource, ProjectResource } from "../resources";
import { BranchesRepository } from "./branchesRepository";
import { DeploymentProcessRepository } from "./deploymentProcessRepository";
import { DeploymentSettingsRepository } from "./deploymentSettingsRepository";
import { ProjectVariablesRepository } from "./projectVariableRepository";
export class ProjectContextRepository {
    DeploymentProcesses: DeploymentProcessRepository;
    DeploymentSettings: DeploymentSettingsRepository;
    Variables: ProjectVariablesRepository;
    Branches: BranchesRepository;
    constructor(client: Client, project: ProjectResource, gitRef: GitRefResource | undefined, requestContext: RequestContext) {
        this.DeploymentProcesses = new DeploymentProcessRepository(client, project, gitRef, requestContext);
        this.DeploymentSettings = new DeploymentSettingsRepository(client, project, gitRef, requestContext);
        this.Variables = new ProjectVariablesRepository(client, project, gitRef, requestContext);
        this.Branches = new BranchesRepository(client, requestContext);
    }
}
