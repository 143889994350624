import React from "react";
import type { PageDefinition } from "./pageId";
export interface PageDetailProps {
    page: PageDefinition;
}
const CurrentPageContext = React.createContext<PageDefinition | undefined>(undefined);
interface PageProviderProps {
    page: PageDefinition;
    children: React.ReactNode;
}
export function PageProvider({ children, page }: PageProviderProps) {
    return <CurrentPageContext.Provider value={page}>{children}</CurrentPageContext.Provider>;
}
PageProvider.displayName = "PageProvider";
interface PageChangedProps {
    onChange: (page: PageDefinition) => void;
}
export function PageChanged({ onChange }: PageChangedProps) {
    const page = usePage();
    const onChangeRef = React.useRef(onChange);
    //We want the latest of the callback for when we notify of page changes, but
    //we do not want the callback to trigger the actual page change effect.
    React.useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);
    React.useEffect(() => {
        if (page) {
            onChangeRef.current(page);
        }
    }, [page]);
    return null;
}
export function usePage() {
    const page = React.useContext(CurrentPageContext);
    if (!page) {
        throw new Error("The Current Page context has not been setup. Please check the component hierarchy and ensure the appropriate context exists above this component.");
    }
    return page;
}
