import type { Client, RequestContext } from "../client";
import type { RouteArgs } from "../resolver";
import type { RunbookRunPreviewRequests, EnvironmentResource, NewRunbookResource, RunbookResource, ResourceCollection, RunbookRunPreviewResource, RunbookRunTemplateResource, RunbookSnapshotResource, RunbookSnapshotTemplateResource, ProjectResource, RunbookProcessResource, RunbooksDashboardItemResource, } from "../resources";
import type { GetRunbookRunDetailsResponse } from "../resources/getRunbookRunDetailsResponse";
import type { RunGitRunbookRunResponse } from "../resources/retryGitRunbookRunResponse";
import type { RunGitRunbookCommand, RunGitRunbookResponse } from "../resources/runGitRunbookCommand";
import type { AllArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type RunbookRepositoryListArgs = {
    skip?: number;
    take?: number;
    orderBy?: string;
} & RouteArgs;
type RunbookRepositoryAllArgs = {
    projectIds?: string[];
} & AllArgs;
export class RunbookRepository extends BasicRepository<RunbookResource, NewRunbookResource, RunbookRepositoryListArgs, RunbookRepositoryAllArgs> {
    constructor(client: Client, requestContext: RequestContext) {
        super("Runbooks", client, requestContext);
    }
    getRunbookSnapshots(runbook: RunbookResource, args?: {
        skip?: number;
        take?: number;
    } & RouteArgs): Promise<ResourceCollection<RunbookSnapshotResource>> {
        return this.client.get<ResourceCollection<RunbookSnapshotResource>>(this.requestContext, runbook.Links["RunbookSnapshots"], args);
    }
    // New hardcoded route methods. These methods will eventually replace all of the
    // ones above (and in the base BasicRepository) that are based on the links collection.
    protected apiBaseRoute: string = "~/api/spaces/{spaceId}";
    protected apiBaseRouteWithProject: string = `${this.apiBaseRoute}/projects/{projectId}{/gitRef}`;
    getRunbook(project: ProjectResource, runbookId: string, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}`;
        return this.client.get<RunbookResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
        });
    }
    createRunbook(resource: NewRunbookResource, spaceId: string, projectId: string, name: string, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/v2`;
        return this.client.post<RunbookResource>(this.requestContext, route, resource, { spaceId, projectId, name, gitRef });
    }
    modifyRunbook(runbook: RunbookResource, gitRef?: string, commitMessage?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}`;
        const runbookWithCommitMessage = {
            ...runbook,
            ChangeDescription: !!gitRef ? commitMessage : undefined,
        };
        return this.client.put<RunbookResource>(this.requestContext, route, runbookWithCommitMessage, {
            spaceId: runbook.SpaceId,
            projectId: runbook.ProjectId,
            runbookId: runbook.Id,
            gitRef,
        });
    }
    deleteRunbook(runbook: RunbookResource, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}`;
        return this.client.delete(this.requestContext, route, null, {
            spaceId: runbook.SpaceId,
            projectId: runbook.ProjectId,
            runbookId: runbook.Id,
            gitRef,
        });
    }
    getRunbookProcess(project: ProjectResource, runbookProcessId: string, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbookProcesses/{runbookProcessId}`;
        return this.client.get<RunbookProcessResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookProcessId,
            gitRef,
        });
    }
    getRunbooks(project: ProjectResource, gitRef?: string | undefined, args?: {
        skip?: number;
        take?: number;
        partialName?: string;
    }) {
        const route = `${this.apiBaseRouteWithProject}/runbooks{?skip,take,partialName}`;
        return this.client.get<ResourceCollection<RunbookResource>>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            gitRef,
            skip: args?.skip,
            take: args?.take,
            partialName: args?.partialName,
        });
    }
    getRunbookRunTemplate(project: ProjectResource, runbookId: string, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/runbookRunTemplate`;
        return this.client.get<RunbookRunTemplateResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
        });
    }
    getRunbookSnapshotTemplate(project: ProjectResource, runbookId: string, gitRef?: string): Promise<RunbookSnapshotTemplateResource> {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/runbookSnapshotTemplate`;
        return this.client.get<RunbookSnapshotTemplateResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
        });
    }
    getRunbookEnvironments(project: ProjectResource, runbookId: string, gitRef?: string): Promise<EnvironmentResource[]> {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/environments`;
        return this.client.get<EnvironmentResource[]>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
        });
    }
    modifyRunbookProcess(runbookProcess: RunbookProcessResource, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbookProcesses/{runbookProcessId}`;
        return this.client.put<RunbookProcessResource>(this.requestContext, route, runbookProcess, {
            spaceId: runbookProcess.SpaceId,
            projectId: runbookProcess.ProjectId,
            runbookProcessId: runbookProcess.Id,
            gitRef,
        });
    }
    // Calls GetRunbookRunPreviewWithoutRequiredTenantController
    getRunbookRunPreview(project: ProjectResource, runbookId: string, environmentId: string, gitRef?: string) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/runbookRuns/preview/{environmentId}{?includeDisabledSteps}`;
        return this.client.get<RunbookRunPreviewResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
            environmentId,
            includeDisabledSteps: true,
        });
    }
    // Calls GetRunbookRunPreviewForRunbookSnapshotController
    getRunbookRunSnapshotPreview(project: ProjectResource, runbookSnapshotId: string, environmentId: string) {
        const route = `${this.apiBaseRouteWithProject}/runbookSnapshots/{runbookSnapshotId}/runbookRuns/preview/{environmentId}{?includeDisabledSteps}`;
        return this.client.get<RunbookRunPreviewResource>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookSnapshotId,
            environmentId,
            includeDisabledSteps: true,
        });
    }
    getTaskList(project: ProjectResource, runbookId?: string, args?: {
        skip?: number;
        take?: number;
        taskIds?: string[];
    }) {
        const route = `${this.apiBaseRoute}/progression/runbooks/taskRuns{?projectIds,environmentIds,runbookIds,tenantIds,taskIds,skip,take}`;
        return this.client.get<ResourceCollection<RunbooksDashboardItemResource>>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectIds: project.Id,
            runbookIds: runbookId ? [runbookId] : undefined,
            taskIds: args?.taskIds,
            skip: args?.skip,
            take: args?.take,
        });
    }
    runGitRunbook(project: ProjectResource, runbookId: string, gitRef: string, command: RunGitRunbookCommand) {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/run/v1`;
        return this.client.post<RunGitRunbookResponse>(this.requestContext, route, command, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId: runbookId,
            gitRef: gitRef,
        });
    }
    retryGitRunbook(project: ProjectResource, runbookRunId: string) {
        const route = `${this.apiBaseRouteWithProject}/runbookruns/{runbookRunId}/retry/v1`;
        return this.client.post<RunGitRunbookRunResponse>(this.requestContext, route, null, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookRunId: runbookRunId,
        });
    }
    getRunbookRunDetails(project: ProjectResource, runbookRunId: string) {
        const route = `${this.apiBaseRouteWithProject}/runbookruns/{runbookRunId}/details/v1`;
        return this.client.get<GetRunbookRunDetailsResponse>(this.requestContext, route, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookRunId: runbookRunId,
        });
    }
    runbookRunTenantPreviews(project: ProjectResource, runbookId: string, runbookRunPreviews: RunbookRunPreviewRequests, gitRef?: string): Promise<RunbookRunPreviewResource[]> {
        const route = `${this.apiBaseRouteWithProject}/runbooks/{runbookId}/runbookRuns/previews`;
        return this.client.post<RunbookRunPreviewResource[]>(this.requestContext, route, runbookRunPreviews, {
            spaceId: project.SpaceId,
            projectId: project.Id,
            runbookId,
            gitRef,
        });
    }
}
