export function isArrayLengthAtLeast<T, Length extends PossibleLengths>(array: Array<T>, length: Length): array is ArrayAtLeastLength<T, Length> {
    return array.length >= length;
}
export type NonEmptyArray<T> = ArrayAtLeastLength<T, 1>;
export type ArrayAtLeastLength<T, Length extends PossibleLengths> = ArrayThatIsAtLeastLengthInternal<T, Length, [
]>;
type ArrayThatIsAtLeastLengthInternal<T, Length extends PossibleLengths, AccumulatedArrayHead extends T[]> = Length extends AccumulatedArrayHead["length"] ? [
    ...AccumulatedArrayHead,
    ...Array<T>
] : ArrayThatIsAtLeastLengthInternal<T, Length, [
    ...AccumulatedArrayHead,
    T
]>; // This mapped type is constructed in a way to ensure it is tail-recursive in this branch, hopefully allowing tail recursion elimination in typescript >=4.5
// If we upgrade to typescript >=4.5, we might be able to make use of Tail Recursion elimination on conditional types https://devblogs.microsoft.com/typescript/announcing-typescript-4-5/#tailrec-conditional
// to allow us to handle an arbitrary array length
// Until then, typescript complains about possibly infinite type recursion if we leave PossibleLengths unbounded.
type PossibleLengths = 0 | 1 | 2 | 3 | 4 | 5;
