import { useOctopusPathResolver } from "@octopusdeploy/octopus-react-client";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { DropdownListItem } from "../../List/DropdownListItem";
import type { ListElement } from "../../List/List";
import { List } from "../../List/List";
interface SpaceListProps {
    spaces: SpaceResource[];
    onSpaceSelected?: () => void;
}
export const SpaceList = React.forwardRef<ListElement, SpaceListProps>(({ spaces, onSpaceSelected }, ref) => {
    const resolvePath = useOctopusPathResolver();
    return (<List ref={ref} items={spaces} renderListItem={(space, index, tabIndex, ref) => (<DropdownListItem onClick={onSpaceSelected} key={space.Id} title={space.Name} description={space.Description} href={links.dashboardPage.generateUrl({ spaceId: space.Id })} logo={{ href: resolvePath(space.Links.Logo), accessibleName: `${space.Name} logo` }} tabIndex={tabIndex} ref={ref}/>)} accessibleName="Spaces"/>);
});
SpaceList.displayName = "SpaceList";
