import { TriggerFilterResource, TriggerFilterType } from "./triggerResource";
export class TriggerGitFilterResource extends TriggerFilterResource {
    constructor(sources: GitTriggerSourceResource[]) {
        super();
        this.FilterType = TriggerFilterType.GitFilter;
        this.Sources = sources;
    }
    Sources: GitTriggerSourceResource[] = [];
}
export interface GitTriggerSourceResource {
    DeploymentActionSlug: string;
    GitDependencyName?: string;
    IncludeFilePaths: string[];
    ExcludeFilePaths: string[];
}
